import { useState } from 'react';

import {
  NewButton,
  NewButtonProps,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@eluve/components';

import { useCopyContentElement } from './useCopyContentElement';

interface CopyRichTextButtonProps {
  getContentElement?: () => HTMLElement | null;
  hideContentInTooltip?: boolean;
  markdownContent?: string;
}

export const CopyRichTextButton: React.FC<
  NewButtonProps & CopyRichTextButtonProps
> = ({
  getContentElement,
  hideContentInTooltip = false,
  markdownContent,
  ...buttonProps
}) => {
  const [tooltipContent, setTooltipContent] = useState('Copy to clipboard');
  const [open, setOpen] = useState(false);

  const handleClick = useCopyContentElement({
    markdownContent,
    element: getContentElement?.(),
    onCopied: () => {
      setTooltipContent('Copied!');
      setOpen(true);
      setTimeout(() => setTooltipContent('Copy to clipboard'), 3000);
    },
  });

  return (
    <Tooltip
      delayDuration={0}
      open={open}
      onOpenChange={setOpen}
      disableHoverableContent={hideContentInTooltip}
    >
      <TooltipTrigger asChild>
        <NewButton
          icon={{ name: 'Copy' }}
          onClick={handleClick}
          type="ghost"
          {...buttonProps}
        />
      </TooltipTrigger>
      <TooltipContent sideOffset={8}>{tooltipContent}</TooltipContent>
    </Tooltip>
  );
};
