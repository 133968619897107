import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useApiClient } from '@eluve/api-client-provider';
import { HStack, Input, NewButton, toast } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import {
  useAssignedTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';
import { userSignatureFileFragment } from '@eluve/shared-gql-operations';
import { useUploadUserFile } from '@eluve/smart-blocks';

export const getUserSignatureQuery = graphql(
  `
    query getUserSignature($userId: uuid!) {
      usersByPk(id: $userId) {
        ...UserSignatureFile
      }
    }
  `,
  [userSignatureFileFragment],
);

const SignatureSettings = () => {
  const [signatureUrl, setSignatureUrl] = useState<string | undefined>();
  const isUpdating = useRef(false);
  const userId = useUserIdFromSession();
  const tenantId = useAssignedTenantIdFromParams();
  const apiClient = useApiClient();
  const { uploadUserFileToGCS } = useUploadUserFile(tenantId);

  const { data, refetch } = useQuery(getUserSignatureQuery, {
    variables: { userId },
  });

  const signatureFile = data?.usersByPk?.userFiles?.[0];

  const downloadFile = useCallback(
    async (fileId: string) => {
      const response = await apiClient.users.getUserFileSignedUrl({
        params: {
          tenantId,
          userId,
          fileId,
        },
      });

      if (response.status === 200 && response.body.presignedUrl) {
        setSignatureUrl(response.body.presignedUrl);
        isUpdating.current = false;
      }
    },
    [apiClient, tenantId, userId],
  );

  useEffect(() => {
    if (!signatureFile) {
      return;
    }
    downloadFile(signatureFile.fileId);
  }, [downloadFile, signatureFile]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    try {
      isUpdating.current = true;

      const file = event.target.files?.[0];
      const extension = file?.name.split('.').pop();
      if (!file || !extension) {
        isUpdating.current = false;
        toast.error('Invalid file extension.');
        return;
      }

      const success = await uploadUserFileToGCS({
        file,
        mimeType: 'image/*',
        extension,
        fileType: 'SIGNATURE',
      });

      if (!success) {
        isUpdating.current = false;
        toast.error('We could not upload the file.');
        return;
      }

      refetch();
    } catch {
      isUpdating.current = false;
      toast.error('We could not update your signature.');
    }
  };

  const handleFileDelete = async () => {
    if (!signatureFile?.fileId) {
      return;
    }

    isUpdating.current = true;

    await apiClient.users.markUserFileUploadDeleted({
      body: { userUploadId: signatureFile.fileId },
      params: { tenantId },
    });

    refetch();

    setSignatureUrl(undefined);

    isUpdating.current = false;
  };

  return (
    <div className="w-1/2 pr-1">
      {signatureUrl ? (
        <HStack
          className="rounded-lg border border-borderPrimary bg-backgroundPrimary p-4"
          gap={4}
          justify="between"
        >
          <img
            alt="Signature"
            className="max-h-20 min-w-0"
            src={signatureUrl}
          />
          <NewButton
            disabled={isUpdating.current}
            icon={{ name: 'Trash' }}
            onClick={handleFileDelete}
            type="outline"
          />
        </HStack>
      ) : (
        <Input
          accept="image/*"
          className="rounded-lg border border-borderPrimary bg-backgroundPrimary"
          disabled={isUpdating.current}
          onChange={handleFileChange}
          type="file"
        />
      )}
    </div>
  );
};

export default SignatureSettings;
