import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { match } from 'ts-pattern';
import { validate as validUUID } from 'uuid';

import { useApiClient } from '@eluve/api-client-provider';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  H2,
  H4,
  HStack,
  Input,
  NewButton,
  P,
  RadioGroup,
  RadioGroupItem,
  VStack,
  textStyles,
  toast,
} from '@eluve/components';
import { formatToLocale } from '@eluve/date-utils';
import { DbEnums } from '@eluve/graphql-types';
import { useNamedLogger } from '@eluve/logger';
import { useTenantIdFromParams } from '@eluve/session-helpers';

enum AssetDeletionRequestTypesEnum {
  AUDIO_FILES = 'audio-files',
  TRANSCRIPTS_AND_AUDIO = 'transcripts-and-audio',
  OUTPUTS = 'outputs',
}

export const SubmitAssetDeletionRequestPage: React.FC = () => {
  const logger = useNamedLogger('AssetDeletionDash');
  const tenantId = useTenantIdFromParams();
  const [requestType, setRequestType] = useState<AssetDeletionRequestTypesEnum>(
    AssetDeletionRequestTypesEnum.AUDIO_FILES,
  );
  const [appointmentId, setAppointmentId] = useState<string>('');
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const apiClient = useApiClient();

  const submitAssetDeletionRequest = async () => {
    logger.debug('submitAssetDeletionRequest');

    if (!appointmentId || !tenantId) {
      return;
    }

    setIsSubmitting(true);

    const jobType = match(requestType)
      .with(AssetDeletionRequestTypesEnum.AUDIO_FILES, () => {
        return DbEnums.AssetDeletionJobType.AUDIO_FOR_APPOINTMENT;
      })
      .with(AssetDeletionRequestTypesEnum.TRANSCRIPTS_AND_AUDIO, () => {
        return DbEnums.AssetDeletionJobType
          .TRANSCRIPTS_AND_AUDIO_FOR_APPOINTMENT;
      })
      .with(AssetDeletionRequestTypesEnum.OUTPUTS, () => {
        return DbEnums.AssetDeletionJobType.APPOINTMENT_OUTPUTS;
      })
      .exhaustive();

    const response = await apiClient.adminAppointment.deleteAppointmentAssets({
      body: {
        jobType,
      },
      params: {
        tenantId,
        appointmentId,
      },
    });

    if (response.status === 201) {
      const runAt = response.body.willRunAt;
      setAppointmentId('');
      toast.success('Success!', {
        description: `Job scheduled for deletion. ${runAt ? 'Job will run at ' + formatToLocale(new Date(runAt).toISOString()) : ''}`,
      });
    }

    if (response.status === 400) {
      toast.error('Failed to submit asset deletion request', {
        description: response.body.message,
      });
    }

    setIsSubmitting(false);
    setConfirmationDialogOpen(false);
  };

  const mapTypeToBeingDeletedString = (type: AssetDeletionRequestTypesEnum) =>
    match(type)
      .with(AssetDeletionRequestTypesEnum.AUDIO_FILES, () => 'audio files')
      .with(
        AssetDeletionRequestTypesEnum.TRANSCRIPTS_AND_AUDIO,
        () => 'audio files and transcripts',
      )
      .with(
        AssetDeletionRequestTypesEnum.OUTPUTS,
        () => 'all LLM and Human Outputs',
      )
      .exhaustive();

  return (
    <>
      {/* SUBMIT DELETION FORM */}
      <VStack gap={8}>
        <H4>Submit an asset deletion request </H4>

        <VStack gap={2}>
          <p className={textStyles.body({ size: 'l' })}>
            Select which assets should be deleted
          </p>
          <RadioGroup
            defaultValue="audio-files"
            onValueChange={(value: AssetDeletionRequestTypesEnum) => {
              setRequestType(value);
            }}
          >
            <HStack justify="start" align="start">
              <RadioGroupItem
                value={AssetDeletionRequestTypesEnum.AUDIO_FILES}
                className="mt-1"
              />
              <VStack gap={0}>
                <p
                  className={textStyles.body({
                    size: 'm',
                    weight: 'bold',
                  })}
                >
                  Delete Audio Files Only
                </p>
                <p className={textStyles.body({ size: 'm' })}>
                  Permanently deletes audio files associated with the given
                  appointment. This job is executed 1 day from the time it is
                  submitted.
                </p>
              </VStack>
            </HStack>

            <HStack justify="start" align="start">
              <RadioGroupItem
                value={AssetDeletionRequestTypesEnum.TRANSCRIPTS_AND_AUDIO}
                className="mt-1"
              />
              <VStack gap={0}>
                <p className={textStyles.body({ size: 'm', weight: 'bold' })}>
                  Delete Audio and Transcrips
                </p>
                <p className={textStyles.body({ size: 'm' })}>
                  Permanently deletes audio files and transcripts associated
                  with the given appointment. This job is executed 5 days from
                  the time it is submitted.
                </p>
              </VStack>
            </HStack>

            <HStack justify="start" align="start">
              <RadioGroupItem
                value={AssetDeletionRequestTypesEnum.OUTPUTS}
                className="mt-1"
              />
              <VStack gap={0}>
                <p className={textStyles.body({ size: 'm', weight: 'bold' })}>
                  Delete all LLM and Human Outputs
                </p>
                <p className={textStyles.body({ size: 'm' })}>
                  Permanently deletes all human and LLM outputs for this
                  appointment. This job is executed 5 days from the time it is
                  submitted.
                </p>
              </VStack>
            </HStack>
          </RadioGroup>
        </VStack>
        <VStack gap={2}>
          <p className={textStyles.body({ size: 'l' })}>
            Enter the appointment ID
          </p>
          <Input
            value={appointmentId}
            onChange={(e) => setAppointmentId(e.target.value)}
            className="w-[400px]"
          />
        </VStack>

        <NewButton
          text="Submit Deletion Request"
          onClick={() => setConfirmationDialogOpen(true)}
          disabled={!validUUID(appointmentId)}
        />
      </VStack>

      {/* SUBMIT DELETION DIALOG */}
      <Dialog
        open={confirmationDialogOpen}
        onOpenChange={setConfirmationDialogOpen}
      >
        <DialogContent className="gap-6">
          <H2>Are you sure?</H2>
          <P>
            Are you sure you want to delete{' '}
            <strong>{mapTypeToBeingDeletedString(requestType)}</strong> for
            appointment{' '}
            <Link
              to={`/admin/tenant/${tenantId}/appointment/${appointmentId}`}
              className="text-positive600"
            >
              {appointmentId}
            </Link>
            ?
          </P>
          <P className="text-xs">
            If yes, this job will be scheduled for execution with a delay. If
            you'd like to cancel the job before it runs, you can delete the
            entry from the <code>graphile_worker.jobs</code> database table.
          </P>
          <DialogFooter>
            <NewButton
              type="subtle"
              disabled={isSubmitting}
              onClick={() => setConfirmationDialogOpen(false)}
              text="Cancel"
            />
            <NewButton
              onClick={submitAssetDeletionRequest}
              disabled={isSubmitting}
              text="Submit Request"
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
