import React from 'react';
import { match } from 'ts-pattern';

import { Tag, TagProps } from '@eluve/components';
import { AppointmentStatusEnum } from '@eluve/graphql-types';

/**
 * Computed status for an appointment based on the status and whether it has been signed, summarized
 * or has amendments
 * These match values returned in the computed field through Hasura that is located in
 * @see hasura/db_objects/public/function/appointment_status_function.sql
 *
 */
export const computedAppointmentStatuses = [
  'AMENDED',
  'AMENDING',
  'SIGNED',
  'SUMMARY_AVAILABLE',
  'ACTIVE',
  'NOT_STARTED',
  'CANCELLED',
] as const;

/**
 * Possible values for logical appointment status labels
 */
export type ComputedAppointmentStatus =
  (typeof computedAppointmentStatuses)[number];

const matchComputedLabelToProps = (
  props: ComputedAppointmentStatus | (string & {}),
) => {
  return match<
    ComputedAppointmentStatus | (string & {}),
    { label: string; color: TagProps['color'] }
  >(props)
    .with('AMENDED', () => ({
      label: 'Signed - amended',
      color: 'default',
    }))
    .with('AMENDING', () => ({
      label: 'Amending - must re-sign',
      color: 'orange',
    }))
    .with('SIGNED', () => ({
      label: 'Signed',
      color: 'default',
    }))
    .with('SUMMARY_AVAILABLE', () => ({
      label: 'Summary Available',
      color: 'purple',
    }))
    .with('COMPLETED', () => ({
      label: 'Completed',
      color: 'orange',
    }))
    .with('ACTIVE', () => ({
      label: 'Active',
      color: 'green',
    }))
    .with('CANCELLED', () => ({
      label: 'Cancelled',
      color: 'yellow',
    }))
    .otherwise(() => ({
      label: 'Not Started',
      color: 'teal',
    }));
};

export interface AppointmentStatusLabelProps {
  isSigned: boolean;
  isSummarized: boolean;
  status: AppointmentStatusEnum | null;
  size?: TagProps['size'];
  isAmending: boolean;
  isAmended: boolean;
}

const matchProps = (props: AppointmentStatusLabelProps) => {
  return match<
    AppointmentStatusLabelProps,
    { label: string; color: TagProps['color'] }
  >(props)
    .with({ isSigned: true, isAmended: true }, () => ({
      label: 'Signed - amended',
      color: 'default',
    }))
    .with({ isSigned: true, isAmended: false, isAmending: true }, () => ({
      label: 'Amending - must re-sign',
      color: 'orange',
    }))
    .with({ isSigned: true, isAmended: false, isAmending: false }, () => ({
      label: 'Signed',
      color: 'default',
    }))
    .with({ isSummarized: true, isSigned: false }, () => ({
      label: 'Summary Available',
      color: 'purple',
    }))
    .with({ status: 'COMPLETED' }, () => ({
      label: 'Completed',
      color: 'orange',
    }))
    .with({ status: 'ACTIVE' }, () => ({
      label: 'Active',
      color: 'green',
    }))
    .with({ status: 'CANCELLED' }, () => ({
      label: 'Cancelled',
      color: 'yellow',
    }))
    .otherwise(() => ({
      label: 'Not Started',
      color: 'teal',
    }));
};

export const appointmentStatusAccessorFn = (
  props: AppointmentStatusLabelProps,
) => {
  return matchProps(props).label;
};

/**
 * @deprecated Use the computed appointment status label instead
 * @see {@link ComputedAppointmentStatusLabel}
 */
export const AppointmentStatusLabel: React.FC<AppointmentStatusLabelProps> = (
  props,
) => {
  const { size = 's' } = props;
  const { label, color } = matchProps(props);
  return <Tag color={color} label={label} size={size} />;
};

export const ComputedAppointmentStatusLabel: React.FC<{
  statusLabel: string;
  size?: TagProps['size'];
}> = (props) => {
  const { size = 's', statusLabel } = props;
  const { label, color } = matchComputedLabelToProps(statusLabel);
  return <Tag color={color} label={label} size={size} />;
};
