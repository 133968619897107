import { useSuspenseQuery } from '@apollo/client';
import { useReactTable } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { match } from 'ts-pattern';

import {
  ComputedAppointmentStatusLabel,
  computedAppointmentStatuses,
} from '@eluve/blocks';
import {
  ColDefBuilder,
  DataTableFilterField,
  Link,
  NewButton,
  ServerDataTable,
  VStack,
  useDataTableForQuery,
} from '@eluve/components';
import { AppointmentsBoolExp, AppointmentsOrderBy } from '@eluve/graphql-types';
import { ResultOf, graphql } from '@eluve/graphql.tada';
import {
  useAssignedTenantIdFromParams,
  useIsTenantAdminFromSession,
  useUserIdFromSession,
} from '@eluve/session-helpers';
import { useIsFeatureFlagEnabled } from '@eluve/smart-blocks';
import { formatHumanName, getUserName } from '@eluve/utils';

/**
 * Retrieves necessary backend values to build filter fields to be used in the table
 */
const appointmentsFilterOptionsQuery = graphql(`
  query appointmentsFilterOptions($tenantId: uuid!) {
    tenantsByPk(id: $tenantId) {
      locations {
        __typename
        id
        name
      }
      tenantUsers {
        __typename
        tenantId
        userId
        user {
          __typename
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`);

const searchTenantAppointmentsQuery = graphql(`
  query searchTenantAppointments(
    $filter: AppointmentsBoolExp
    $offset: Int
    $orderBy: [AppointmentsOrderBy!]
    $limit: Int
  ) {
    appointments(
      where: $filter
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      __typename
      tenantId
      id
      name
      startDate
      statusLabel
      createdAt
      updatedAt
      user {
        __typename
        id
        firstName
        lastName
        email
      }
      location {
        __typename
        id
        name
      }
      patient {
        __typename
        id
        firstName
        lastName
      }
    }
    appointmentsAggregate(where: $filter) {
      aggregate {
        count
      }
    }
  }
`);

type TableRow = {
  tenantId: string;
  id: string;
  name: string;
  statusLabel: string;
  locationId: string | null;
  locationName: string | null;
  updatedAt: string;
  startDate: string | null;
  userId: string;
  userName: string;
  patientId: string | null;
  patientName: string | null;
};

const convertToRows = (
  results: ResultOf<typeof searchTenantAppointmentsQuery>,
): TableRow[] => {
  return (results?.appointments ?? []).map<TableRow>((a) => {
    return {
      tenantId: a.tenantId,
      id: a.id,
      name: a.name,
      locationId: a.location?.id ?? null,
      locationName: a.location?.name ?? null,
      statusLabel: a.statusLabel ?? '',
      startDate: a.startDate ?? null,
      // TODO(jesse)[ELU-4002] Use last interacted with date from query
      updatedAt: a.updatedAt,
      userId: a.user?.id ?? '',
      userName: getUserName({
        firstName: a.user?.firstName,
        lastName: a.user?.lastName,
        email: a.user?.email,
      }),
      patientId: a.patient?.id ?? null,
      patientName: formatHumanName(a.patient?.firstName, a.patient?.lastName),
    };
  });
};

const columns = new ColDefBuilder<TableRow>()
  .linkSortable(
    'name',
    ({ tenantId, id }) => `/tenants/${tenantId}/appointments/${id}`,
  )
  .colDef({
    id: 'patientName',
    header: 'Patient',
    cell: ({ row }) => (
      <Link to={`../patients/${row.original.patientId}`}>
        <span className="privacy-text">{row.original.patientName}</span>
      </Link>
    ),
  })
  .defaultSortable('userId', {
    label: 'User',
    cellRenderer: ({ userName }) => {
      return <span>{userName}</span>;
    },
  })
  .dateSortable('updatedAt', 'Last Modified')
  .dateSortable('startDate', 'Start Date')
  .defaultSortable('locationId', {
    label: 'Location',
    cellRenderer: ({ locationName }) => {
      return <span>{locationName}</span>;
    },
  })
  .defaultSortable('statusLabel', {
    label: 'Status',
    cellRenderer: ({ statusLabel }) => {
      return <ComputedAppointmentStatusLabel statusLabel={statusLabel} />;
    },
  })
  .build();

export interface FilterableAppointmentsTableProps {
  baseFilterConditions?: AppointmentsBoolExp[];
}

export const FilterableAppointmentsTable: React.FC<
  FilterableAppointmentsTableProps
> = ({ baseFilterConditions = [] }) => {
  const userId = useUserIdFromSession();
  const isTenantAdmin = useIsTenantAdminFromSession();
  const tenantId = useAssignedTenantIdFromParams();

  const isAllPatientAccessEnabled =
    useIsFeatureFlagEnabled('ALL_PATIENT_ACCESS');

  const showUserFilter = isTenantAdmin || isAllPatientAccessEnabled;

  const {
    data: { tenantsByPk: tenant },
  } = useSuspenseQuery(appointmentsFilterOptionsQuery, {
    variables: { tenantId },
  });

  const filterFields = useMemo(() => {
    const fields: DataTableFilterField<TableRow>[] = [
      {
        id: 'locationId',
        label: 'Location',
        options: tenant?.locations.map((l) => ({
          label: l.name,
          value: l.id,
        })),
      },
      {
        id: 'statusLabel',
        label: 'Status',
        options: computedAppointmentStatuses.map((s) => ({
          label: s,
          value: s,
        })),
      },
    ];
    // Only include the ability to filter on users if the user is a tenant admin or has all patient access
    if (showUserFilter) {
      fields.push({
        id: 'userId',
        label: 'User',
        options: tenant?.tenantUsers.map((tu) => ({
          label: getUserName({
            firstName: tu.user?.firstName,
            lastName: tu.user?.lastName,
            email: tu.user?.email,
          }),
          value: tu.userId,
        })),
      });
    }

    return fields;
  }, [tenant, showUserFilter]);

  const {
    rows: serverRows,
    reactTableOptions,
    isPending,
    data,
  } = useDataTableForQuery({
    query: searchTenantAppointmentsQuery,
    convertToRows,
    filterFields,
    convertSearchParamsToVariables: ({ offset, limit, sorting, filters }) => {
      const filterConditions = [...baseFilterConditions];

      if (filters.locationId && filters.locationId.length) {
        filterConditions.push({
          locationId: {
            _in: filters.locationId as string[],
          },
        });
      }

      if (filters.statusLabel && filters.statusLabel.length) {
        filterConditions.push({
          statusLabel: {
            _in: filters.statusLabel as string[],
          },
        });
      }

      if (!showUserFilter) {
        filterConditions.push({
          userId: { _eq: userId },
        });
      } else {
        if (filters.userId && filters.userId.length) {
          filterConditions.push({
            userId: {
              _in: filters.userId as string[],
            },
          });
        }
      }

      return {
        filter: {
          _and: filterConditions,
        },
        offset,
        limit,
        orderBy:
          sorting && sorting.length
            ? sorting.map(({ desc, id }) => ({
                ...match<keyof TableRow, AppointmentsOrderBy>(id)
                  .with('userId', () => ({
                    user: {
                      email: desc ? 'DESC' : 'ASC',
                    },
                  }))
                  .with('locationId', () => ({
                    location: {
                      name: desc ? 'DESC' : 'ASC',
                    },
                  }))
                  .otherwise(() => ({
                    [id]: desc ? 'DESC' : 'ASC',
                  })),
              }))
            : null,
      };
    },
  });

  const rowCount = data?.appointmentsAggregate?.aggregate?.count;

  const filteredColumns = useMemo(() => {
    if (showUserFilter) {
      return columns;
    }

    return columns.filter(
      (f) =>
        (f as unknown as Record<string, unknown>)?.accessorKey !== 'userId',
    );
  }, [showUserFilter]);

  const table = useReactTable({
    data: serverRows,
    columns: filteredColumns,
    rowCount,
    ...reactTableOptions,
  });

  const showJustMyAppointments = () =>
    table.getColumn('userId')?.setFilterValue([userId]);

  return (
    <VStack className="rounded-md border p-2" gap={3}>
      {showUserFilter && (
        <NewButton
          type="outlineFilled"
          size="s"
          text="Only my appointments"
          icon={{
            name: 'User',
          }}
          onClick={showJustMyAppointments}
        />
      )}
      <ServerDataTable<TableRow>
        filterFields={filterFields}
        table={table}
        isPending={isPending}
      />
    </VStack>
  );
};
