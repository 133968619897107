import React from 'react';

import { VStack } from '@eluve/components';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@eluve/components';
import {
  useAppointmentMode,
  useIsUserAppointmentOwner,
  useUpdateAppointmentMode,
} from '@eluve/frontend-appointment-hooks';
import { AppointmentModeTypesEnum, DbEnums } from '@eluve/graphql-types';
import { useIsFeatureFlagEnabled } from '@eluve/smart-blocks';

import { NewAppointmentFormDefault } from './components/NewAppointment/NewAppointmentFormDefault';
import { NewAppointmentFormManualMode } from './components/NewAppointment/NewAppointmentFormManualMode';
import { PlayingDisclaimer } from './components/PlayingDisclaimer';
import { useTranscriptionMachineSelector } from './machines/transcription/TranscriptionMachineProvider';

export interface NewAppointmentFormProps {
  tenantId: string;
  appointmentId: string;
  isCompactMicrophone: boolean;
  isSettingsAvailable: boolean;
}

export const NewAppointmentForm: React.FC<NewAppointmentFormProps> = ({
  tenantId,
  appointmentId,
  isCompactMicrophone,
  isSettingsAvailable,
}) => {
  const isPlayingDisclaimer = useTranscriptionMachineSelector((state) =>
    state.matches({ InSession: 'PlayingDisclaimer' }),
  );

  const isUserApptOwner = useIsUserAppointmentOwner();
  const appointmentMode = useAppointmentMode();
  const updateAppointmentMode = useUpdateAppointmentMode();
  const isManualChartingEnabled = useIsFeatureFlagEnabled(
    'MANUAL_CHARTING_MODE',
  );
  const showTabs = isManualChartingEnabled;

  const onModeSelected = (tab: AppointmentModeTypesEnum) => {
    updateAppointmentMode({
      variables: {
        tenantId: tenantId,
        appointmentId: appointmentId,
        mode: tab,
      },
    });
  };

  if (isPlayingDisclaimer) {
    return <PlayingDisclaimer />;
  }

  return (
    <VStack wFull gap={6} className="justify-stretch pt-8">
      {showTabs && (
        <Tabs defaultValue={appointmentMode} className="w-full">
          <TabsList className="mb-5 grid w-fit grid-cols-2">
            <TabsTrigger
              value={DbEnums.AppointmentMode.AUDIO_COPILOT}
              onClick={() =>
                onModeSelected(DbEnums.AppointmentMode.AUDIO_COPILOT)
              }
              disabled={!isUserApptOwner}
            >
              Transcribe
            </TabsTrigger>
            <TabsTrigger
              value={DbEnums.AppointmentMode.MANUAL_CHARTING}
              onClick={() =>
                onModeSelected(DbEnums.AppointmentMode.MANUAL_CHARTING)
              }
              disabled={!isUserApptOwner}
            >
              Manual Charting
            </TabsTrigger>
          </TabsList>

          <TabsContent value={DbEnums.AppointmentMode.AUDIO_COPILOT}>
            <NewAppointmentFormDefault
              isCompactMicrophone={isCompactMicrophone}
              isSettingsAvailable={isSettingsAvailable}
            />
          </TabsContent>

          <TabsContent value={DbEnums.AppointmentMode.MANUAL_CHARTING}>
            <NewAppointmentFormManualMode
              isSettingsAvailable={isSettingsAvailable}
            />
          </TabsContent>
        </Tabs>
      )}

      {!showTabs && (
        <NewAppointmentFormDefault
          isCompactMicrophone={isCompactMicrophone}
          isSettingsAvailable={isSettingsAvailable}
        />
      )}
    </VStack>
  );
};
