import { getFormattedDateInMMMMdyyyyWithTime } from '@eluve/date-utils';
import { getUserName } from '@eluve/utils';

export const getAmendmentHeader = ({
  submittedAt,
  firstName,
  lastName,
  email,
}: {
  submittedAt: string | Date;
  firstName?: string;
  lastName?: string;
  email?: string;
}) => {
  const formattedDate = getFormattedDateInMMMMdyyyyWithTime(submittedAt);
  const userName = getUserName({
    firstName,
    lastName,
    email,
  });
  return `Amendment • ${formattedDate} by ${userName}`;
};
