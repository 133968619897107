import React, { Suspense } from 'react';

import {
  Content,
  Header,
  PageTitle,
  SidebarTrigger,
  TableSkeleton,
} from '@eluve/components';
import { FilterableAppointmentsTable } from '@eluve/frontend-feature-appointment';
import { useCaptureEventOnMount } from '@eluve/posthog-react';

export const AppointmentsListPage: React.FC = () => {
  useCaptureEventOnMount('page_view_appointments_list');

  return (
    <>
      <Header>
        <SidebarTrigger />
        <PageTitle>Appointments</PageTitle>
      </Header>
      <Content padded>
        <Suspense fallback={<TableSkeleton numRows={20} />}>
          <FilterableAppointmentsTable />
        </Suspense>
      </Content>
    </>
  );
};
