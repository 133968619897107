import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import format from 'date-fns/format';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { tv } from 'tailwind-variants';

import { PdfTable } from './Table';
import { tw } from './pdf-tw';

export type SuperBillLineItem = {
  code: string;
  description: string;
  modifier: string;
  diagnosisPointer: string;
  fee: string;
  quantity: string;
  total: string;
};

export type SuperbillProps = {
  logoUrl?: string;
  patientInfo: {
    name: string;
    dob: string;
    address?: string;
  };
  location?: {
    address?: string | null;
    businessName?: string | null;
    phoneNumber?: string | null;
    placeOfServiceCode?: string | null;
    taxId?: string | null;
  };
  date: string;
  providerName: string;
  lineItems: SuperBillLineItem[];
  diagnosisCodes?: {
    id: string;
    number: string;
    code: string;
    description: string;
  }[];
  totalCharges: string;
  signatureUrl?: string;
};

const styles = tv({
  slots: {
    row: 'flex w-full flex-row gap-4 px-1 text-sm',
  },
});

const { row } = styles();

const Header = ({ text }: { text: string }) => {
  return (
    <View style={tw('w-full bg-brandGray950 p-2 text-sm')}>
      <Text style={tw('text-brandGray50')}>{text}</Text>
    </View>
  );
};

export const SuperbillPdf = (props: SuperbillProps) => {
  const {
    logoUrl,
    patientInfo,
    lineItems,
    totalCharges,
    providerName,
    diagnosisCodes = [],
    location = {},
    date,
    signatureUrl,
  } = props;

  let placeOfService = '';
  if (location.businessName) {
    placeOfService += location.businessName;
  }
  if (location.placeOfServiceCode) {
    placeOfService += `\nPlace of Service Code: ${location.placeOfServiceCode}`;
  }
  if (location.address) {
    placeOfService += `\n${location.address}`;
  }

  return (
    <Document>
      <Page
        size="A4"
        style={tw(
          'px-12 py-8 pb-16 text-brandGray900 font-[Inter] flex flex-col justify-start',
        )}
      >
        <View style={tw('flex flex-row w-full justify-between')}>
          {logoUrl ? (
            <Image style={tw('max-w-64 max-h-16')} src={logoUrl}></Image>
          ) : (
            <View />
          )}

          <View style={tw('flex flex-col gap-1.5 text-xs items-end')}>
            {Boolean(location?.businessName) && (
              <Text style={tw('font-semibold')}>{location.businessName}</Text>
            )}
            {Boolean(location?.address) && <Text>{location.address}</Text>}
            {(Boolean(location?.taxId) || Boolean(location?.phoneNumber)) && (
              <Text>
                {location?.taxId && `Tax ID: ${location.taxId}`}
                {location?.phoneNumber && `  Tel: ${location.phoneNumber}`}
              </Text>
            )}
          </View>
        </View>

        <Text
          style={tw(
            'text-3xl mt-6 mb-2 leading-6 font-light text-brandGray700',
          )}
        >
          Superbill
        </Text>

        <View style={tw('flex flex-col w-full gap-2')}>
          <Header text="Session Information" />
          <PdfTable<'date' | 'provider' | 'placeOfService'>
            columns={{
              date: {
                label: 'Date of Visit',
                width: 20,
              },
              provider: {
                width: 30,
              },
              placeOfService: {
                label: 'Place of Service',
                width: 50,
              },
            }}
            rows={[
              {
                date: date,
                provider: providerName,
                placeOfService: placeOfService || '--',
              },
            ]}
          />
          <Header text="Patient Information" />
          <View
            style={tw(
              row({
                className: 'w-full bg-brandGray400',
              }),
            )}
          ></View>
          <PdfTable<'name' | 'dateOfBirth' | 'address'>
            columns={{
              name: {
                width: 20,
              },
              dateOfBirth: {
                label: 'Date of Birth',
                width: 30,
              },
              address: {
                width: 50,
              },
            }}
            rows={[
              {
                name: patientInfo.name,
                dateOfBirth: patientInfo.dob,
                address: patientInfo.address ?? '--',
              },
            ]}
          />
          <Header text="Diagnosis" />
          <PdfTable<'number' | 'code' | 'description'>
            columns={{
              number: {
                label: '#',
                width: 20,
              },
              code: {
                width: 30,
              },
              description: {
                width: 50,
              },
            }}
            rows={diagnosisCodes.map((dc, i) => ({
              number: `${i + 1}`,
              code: dc.code,
              description: dc.description,
            }))}
          />
          <Header text="Session" />
          <PdfTable<
            | 'code'
            | 'description'
            | 'modifier'
            | 'diagnosisPointer'
            | 'fee'
            | 'quantity'
            | 'total'
          >
            columns={{
              code: {
                label: 'Billing Code',
                width: 15,
              },
              description: {
                width: 30,
              },
              modifier: {
                alignText: 'center',
                width: 14,
              },
              diagnosisPointer: {
                alignText: 'center',
                width: 17,
              },
              fee: {
                alignText: 'right',
                width: 8,
              },
              quantity: {
                alignText: 'right',
                width: 8,
              },
              total: {
                alignText: 'right',
                width: 8,
              },
            }}
            rows={lineItems}
          />

          <Header text="Summary" />
          <View style={tw('w-full flex flex-row justify-end px-2')}>
            <View style={tw('grow')}></View>
            <View style={tw('flex flex-row gap-6 items-center text-xs')}>
              <Text style={tw('font-semibold')}>Total Charges</Text>
              <Text>{totalCharges}</Text>
            </View>
          </View>
        </View>

        <View
          style={tw(
            'flex-1 flex flex-row text-xs font-semibold w-full justify-between',
          )}
        >
          <View style={tw('flex flex-col gap-4 justify-end')}>
            <Text>Patient Signature</Text>
            {signatureUrl ? (
              <Image
                style={tw('max-w-32 max-h-8 mt-2')}
                src={signatureUrl}
              ></Image>
            ) : (
              <View style={tw('h-8 w-8 mt-2')} />
            )}
            <Text>Provider Signature</Text>
          </View>
          <View style={tw('flex flex-col gap-4 justify-end mr-12')}>
            <Text>Date</Text>
            <Text style={tw('font-normal mb-2 mt-4')}>
              {format(Date.now(), 'MMMM dd, yyyy')}
            </Text>
            <Text>Date</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
