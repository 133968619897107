import { useEffect, useMemo } from 'react';

import { Box, Select, SelectContent, SelectTrigger } from '@eluve/components';
import { useIsUserAppointmentOwner } from '@eluve/frontend-appointment-hooks';
import {
  PromptTemplateSelectItem,
  usePromptTemplates,
} from '@eluve/frontend-feature-user-settings';

interface OutputTemplateSelectorProps {
  selectedOutputTemplateId?: string;
  onSelectionChanged: (initialOutputTemplateId: string) => void;
}

export const OutputTemplateSelector: React.FC<OutputTemplateSelectorProps> = ({
  selectedOutputTemplateId,
  onSelectionChanged,
}) => {
  const {
    activeOutputTemplates: outputTemplates,
    defaultOutputTemplateId,
    userSettingsOutputTemplateId,
  } = usePromptTemplates();

  const isUserApptOwner = useIsUserAppointmentOwner();

  useEffect(() => {
    if (!isUserApptOwner) {
      // do not set defaults if user is not appointment owner
      return;
    }

    // select the default output template if no output template is selected
    if (!selectedOutputTemplateId && userSettingsOutputTemplateId) {
      onSelectionChanged(userSettingsOutputTemplateId);
    }

    if (
      !selectedOutputTemplateId &&
      !userSettingsOutputTemplateId &&
      defaultOutputTemplateId
    ) {
      onSelectionChanged(defaultOutputTemplateId);
    }
  }, [
    selectedOutputTemplateId,
    defaultOutputTemplateId,
    userSettingsOutputTemplateId,
    onSelectionChanged,
    outputTemplates,
    isUserApptOwner,
  ]);

  const selectedOutputTemplate = useMemo(
    () =>
      outputTemplates.find(
        (template) => template.id === selectedOutputTemplateId,
      ),
    [outputTemplates, selectedOutputTemplateId],
  );

  return (
    <Box vStack className="w-full">
      <Select
        value={selectedOutputTemplateId}
        onValueChange={(outputTemplateId) => {
          onSelectionChanged(outputTemplateId);
        }}
        disabled={!isUserApptOwner}
      >
        <SelectTrigger>
          {selectedOutputTemplate?.name ?? 'Select Output Template'}
        </SelectTrigger>
        <SelectContent>
          {outputTemplates?.map((template) => (
            <PromptTemplateSelectItem
              key={template.id}
              templateId={template.id ?? ''}
              templateName={template.name ?? ''}
              templateDescription={null}
            />
          ))}
        </SelectContent>
      </Select>
    </Box>
  );
};
