import { usePostHog as usePostHogLib } from 'posthog-js/react';

/**
 * A custom wrapper around posthog-js `usePostHog`. Exposes a custom `capture` method
 * which prefixes all event names with the given feature prefix.
 *
 * This is useful for tracking events related to a specific feature.
 */
export const usePostHog = (featurePrefix: string) => {
  const postHog = usePostHogLib();

  const customCapture = (
    eventName: string,
    properties?: Record<string, unknown>,
  ) => {
    if (!postHog) {
      return;
    }

    postHog.capture(`${featurePrefix}:${eventName}`, properties);
  };

  return {
    ...postHog,
    capture: customCapture,
  };
};
