import { z } from 'zod';

import { MatchingRecord } from '@eluve/utils';

import { AppointmentModeTypesLookup } from './appointment-mode-types';
import { AppointmentStatusTypesLookup } from './appointment-status-types';
import { AssetDeletionJobTypesLookup } from './asset-deletion-job-types';
import { FeedbackTypeLookup } from './feedback-types';
import { MedicalCodeTypesLookup } from './medical-code-types';
import { LlmModelTypesEnum, LlmOutputTypesEnum } from './types';
import { WorkerStatusTypesEnum } from './types';
import { UserUploadStatusLookup } from './user-upload-status-types';

/**
 * Provides a lookup object for the LlmOutputTypesEnum that can also
 * be used as a zod enum for runtime validation
 */
export const LlmOutputTypesLookup = {
  SOAP_NOTE: 'SOAP_NOTE', // At the moment, SOAP_NOTE represents any medical chart.
  CLIENT_RECAP: 'CLIENT_RECAP',
  FACT_VERIFICATION: 'FACT_VERIFICATION',
  BILLING_CODE_RECOMMENDATION: 'BILLING_CODE_RECOMMENDATION',
  DIFFERENTIAL_DIAGNOSES: 'DIFFERENTIAL_DIAGNOSES',
  GENERIC_OUTPUT: 'GENERIC_OUTPUT',
  REVIEW_OF_SYSTEMS: 'REVIEW_OF_SYSTEMS',
  PFSH: 'PFSH',
  FACT_GENERATION: 'FACT_GENERATION',
  CHIEF_COMPLAINT: 'CHIEF_COMPLAINT',
  PII_REMOVAL: 'PII_REMOVAL',
  DYNAMIC_OUTPUT: 'DYNAMIC_OUTPUT',
  TRANSLATION: 'TRANSLATION',
  MEDICINE_RECOMMENDATION: 'MEDICINE_RECOMMENDATION',
  PATIENT_OVERVIEW: 'PATIENT_OVERVIEW',
  DIFFERENTIAL_DIAGNOSES_PLUS_RED_FLAGS:
    'DIFFERENTIAL_DIAGNOSES_PLUS_RED_FLAGS',
  DIAGNOSES_JUSTIFICATION: 'DIAGNOSES_JUSTIFICATION',
} as const satisfies MatchingRecord<LlmOutputTypesEnum>;

export const MedicalNotesLlmOutputTypes = [
  LlmOutputTypesLookup.DYNAMIC_OUTPUT,
  LlmOutputTypesLookup.SOAP_NOTE,
];

export const isLlmOutputMedicalNote = (
  outputType: LlmOutputTypesEnum | string = '',
) =>
  MedicalNotesLlmOutputTypes.map((type) => type.toString()).includes(
    outputType?.toString(),
  );

export const isLlmOutputDynamicOutput = (
  outputType?: LlmOutputTypesEnum | string | null,
) => LlmOutputTypesLookup.DYNAMIC_OUTPUT.toString() === outputType?.toString();

export const llmOutputTypesSchema = z.nativeEnum(LlmOutputTypesLookup);

export const LlmModelTypesLookup = {
  CLAUDE_2: 'CLAUDE_2',
  CLAUDE_3: 'CLAUDE_3',
  GPT_4: 'GPT_4',
  LLAMA_3: 'LLAMA_3',
  GPT_4_O: 'GPT_4_O',
  DIFY_SERVICE: 'DIFY_SERVICE',
} as const satisfies MatchingRecord<LlmModelTypesEnum>;

export const llmModelTypesSchema = z.nativeEnum(LlmModelTypesLookup);

export type PatientArtifactTypesEnum =
  | 'PATIENT_OVERVIEW'
  | 'LATEST_MEDICAL_NOTE'
  | 'PATIENT_DETAILS'
  | 'ALL_MEDICAL_NOTES'
  | 'LATEST_APPOINTMENT_INTAKE_CONVERSATION';

export const PatientArtifactTypesLookup = {
  PATIENT_OVERVIEW: 'PATIENT_OVERVIEW',
  LATEST_MEDICAL_NOTE: 'LATEST_MEDICAL_NOTE',
  PATIENT_DETAILS: 'PATIENT_DETAILS',
  ALL_MEDICAL_NOTES: 'ALL_MEDICAL_NOTES',
  LATEST_APPOINTMENT_INTAKE_CONVERSATION:
    'LATEST_APPOINTMENT_INTAKE_CONVERSATION',
} as const satisfies MatchingRecord<PatientArtifactTypesEnum>;

export const WorkerStatusTypesLookup = {
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  NOT_STARTED: 'NOT_STARTED',
} as const satisfies MatchingRecord<WorkerStatusTypesEnum>;

export const DbEnums = {
  UserUploadStatus: UserUploadStatusLookup,
  PatientArtifactType: PatientArtifactTypesLookup,
  LlmModelType: LlmModelTypesLookup,
  LlmOutputType: LlmOutputTypesLookup,
  AssetDeletionJobType: AssetDeletionJobTypesLookup,
  AppointmentStatus: AppointmentStatusTypesLookup,
  FeedbackType: FeedbackTypeLookup,
  AppointmentMode: AppointmentModeTypesLookup,
  MedicalCodeTypes: MedicalCodeTypesLookup,
  WorkerStatus: WorkerStatusTypesLookup,
};
