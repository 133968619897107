import { MatchingRecord } from '@eluve/utils';

import { AssetDeletionJobTypesEnum } from './types';

export const AssetDeletionJobTypesLookup = {
  AUDIO_FOR_APPOINTMENT: 'AUDIO_FOR_APPOINTMENT',
  TRANSCRIPTS_AND_AUDIO_FOR_APPOINTMENT:
    'TRANSCRIPTS_AND_AUDIO_FOR_APPOINTMENT',
  APPOINTMENT_OUTPUTS: 'APPOINTMENT_OUTPUTS',
} as const satisfies MatchingRecord<AssetDeletionJobTypesEnum>;
