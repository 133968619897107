import { graphql } from '@eluve/graphql.tada';

export const linkedCodeFragment = graphql(`
  fragment AppointmentBillingCodeLink on AppointmentBillingCodeLinks @_unmask {
    __typename
    id
    sourceAppointmentBillingCodeId
    targetAppointmentBillingCodeId
  }
`);

export const medicalCodeFragment = graphql(`
  fragment MedicalCode on MedicalCodes @_unmask {
    __typename
    id
    code
    codeType
    description
  }
`);

export const tenantMedicalCodeFragment = graphql(`
  fragment TenantMedicalCode on TenantMedicalCodes @_unmask {
    __typename
    id
    code
    codeType
    description
  }
`);

export const appointmentBillingCodeRecommendationsFragment = graphql(
  `
    fragment BillingCodeRecommedations on Appointments @_unmask {
      __typename
      id
      billing_code_recommendations {
        id
        __typename
        codeId
        appointmentId
        isFinal
        medical_code {
          userGroupConfigs {
            __typename
            id
            label
            price
            modifiers
          }
          ...MedicalCode
        }
        tenant_medical_code {
          ...TenantMedicalCode
        }
      }
    }
  `,
  [medicalCodeFragment, tenantMedicalCodeFragment],
);

export const appointmentBillingCodesFragment = graphql(
  `
    fragment BillingCodes on Appointments @_unmask {
      __typename
      id
      billingCodes {
        __typename
        id
        price
        quantity
        modifiers
        description
        linked_codes {
          ...AppointmentBillingCodeLink
        }
        medical_code {
          ...MedicalCode
        }
        tenant_medical_code {
          ...TenantMedicalCode
        }
      }
    }
  `,
  [linkedCodeFragment, medicalCodeFragment, tenantMedicalCodeFragment],
);

export const acceptBillingCodeRecommendationMutation = graphql(`
  mutation acceptBillingCodeRecommendation(
    $appointmentId: uuid!
    $billingCodeId: uuid
    $tenantCodeId: uuid
    $price: Int
    $quantity: Int
    $modifiers: jsonb
    $userGroupCodeConfigId: uuid
    $where: AppointmentBillingCodeRecommendationsBoolExp!
  ) {
    insertAppointmentBillingCodesOne(
      object: {
        appointmentId: $appointmentId
        billingCodeId: $billingCodeId
        tenantCodeId: $tenantCodeId
        price: $price
        quantity: $quantity
        modifiers: $modifiers
        userGroupCodeConfigId: $userGroupCodeConfigId
      }
      onConflict: { constraint: uq_appointment_code, updateColumns: [] }
    ) {
      __typename
      id
      price
      modifiers
    }
    updateAppointmentBillingCodeRecommendations(
      where: $where
      _set: { isFinal: true }
    ) {
      returning {
        id
        __typename
        appointmentId
        codeId
        tenantCodeId
        isFinal
      }
    }
  }
`);

export const addAppointmentBillingCodeMutation = graphql(`
  mutation addBillingCode(
    $appointmentId: uuid!
    $billingCodeId: uuid
    $tenantCodeId: uuid
    $price: Int
    $quantity: Int
    $modifiers: jsonb
    $userGroupCodeConfigId: uuid
  ) {
    insertAppointmentBillingCodesOne(
      object: {
        appointmentId: $appointmentId
        billingCodeId: $billingCodeId
        tenantCodeId: $tenantCodeId
        price: $price
        quantity: $quantity
        userGroupCodeConfigId: $userGroupCodeConfigId
        modifiers: $modifiers
      }
      onConflict: { constraint: uq_appointment_code, updateColumns: [] }
    ) {
      __typename
      id
      price
      quantity
      description
      modifiers
    }
  }
`);

export const removeBillingCodeMutation = graphql(`
  mutation removeBillingCode(
    $appointmentBillingCodeId: uuid!
    $appointmentBillingCodeRecommendationId: uuid
    $skipUpdate: Boolean! = false
  ) {
    deleteAppointmentBillingCodesByPk(id: $appointmentBillingCodeId) {
      id
      __typename
    }
    updateAppointmentBillingCodeRecommendations(
      where: { id: { _eq: $appointmentBillingCodeRecommendationId } }
      _set: { isFinal: false }
    ) @skip(if: $skipUpdate) {
      returning {
        id
        __typename
      }
    }
  }
`);

export const getAppointmentBillingCodesQuery = graphql(
  `
    query getAppointmentBillingCodes($tenantId: uuid!, $appointmentId: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        ...BillingCodes
        ...BillingCodeRecommedations
      }
      tenantsByPk(id: $tenantId) {
        id
        __typename
        tenantMedicalCodesExist
      }
    }
  `,
  [
    appointmentBillingCodesFragment,
    appointmentBillingCodeRecommendationsFragment,
  ],
);

export const getAppointmentBillingCodePrice = graphql(`
  query getAppointmentBillingCodePrice(
    $billingCodeId: uuid!
    $locationId: String!
  ) {
    inheritedBillingCodeLocationPrices(
      where: {
        billingCodeId: { _eq: $billingCodeId }
        locationId: { _eq: $locationId }
        precendence: { _eq: 1 }
      }
      limit: 1
    ) {
      __typename
      billingCodeId
      price
    }
  }
`);
