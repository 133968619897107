import copy from 'copy-to-clipboard';
import { marked } from 'marked';

import { convertMarkdownToPlainText } from '@eluve/utils';

export const useCopyContentElement = ({
  element,
  markdownContent,
  onCopied,
}: {
  element?: HTMLElement | null | undefined;
  markdownContent?: string;
  onCopied?: () => void;
}) => {
  return async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    let htmlContent = '';
    let plainText = '';

    if (element) {
      const clonedElement = element.cloneNode(true) as HTMLElement;
      clonedElement.querySelectorAll('button').forEach((a) => a.remove()); // Remove buttons
      htmlContent = clonedElement.innerHTML;
      plainText = markdownContent
        ? convertMarkdownToPlainText(markdownContent)
        : clonedElement.textContent ?? '';
    } else if (markdownContent) {
      htmlContent = marked(markdownContent);
      plainText = convertMarkdownToPlainText(markdownContent);
    } else {
      return;
    }

    if (typeof ClipboardItem !== 'undefined') {
      const html = new Blob([htmlContent], { type: 'text/html' });
      const text = new Blob([plainText], { type: 'text/plain' });
      const data = new ClipboardItem({ 'text/html': html, 'text/plain': text });
      await navigator.clipboard.write([data]);
    } else {
      copy(htmlContent, { format: 'text/html' });
      copy(plainText);
    }

    onCopied?.();
    e.currentTarget?.blur();
  };
};
