import { useMutation, useSuspenseQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { produce } from 'immer';
import isNil from 'lodash/isNil';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

import { cacheUtils, useCompleteFragment } from '@eluve/apollo-client';
import { MedicalCodeTag } from '@eluve/blocks';
import {
  ColDefBuilder,
  CurrencyInput,
  DataTable,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  Input,
  NewButton,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  VStack,
  currencyStringToCentsSchema,
  toast,
} from '@eluve/components';
import { useSearchAllMedicalCodes } from '@eluve/frontend-feature-appointment';
import { SearchableBillingCodes } from '@eluve/frontend-feature-appointment';
import { MedicalCodeTypesEnum } from '@eluve/graphql-types';
import { useAssignedTenantIdFromParams } from '@eluve/session-helpers';
import { UseDialog, useDialog } from '@eluve/utility-hooks';
import { billingCodeModifiersSchema } from '@eluve/utils';

import { removeCodeConfigFromGroupMutation } from './operations';
import {
  createUserGroupCodeConfigMutation,
  getUserGroupConfigsQuery,
  userGroupConfigsFragment,
} from './user-group-configs.operations';

type Row = {
  tenantId: string;
  userGroupId: string;
  configId: string;
  code: string;
  codeType: MedicalCodeTypesEnum;
  label: string;
  price: number;
  modifiers: string[];
};

const DeleteUserGroupCodeConfigCell: React.FC<Row> = ({
  tenantId,
  userGroupId,
  configId,
}) => {
  const [removeConfigFromGroup] = useMutation(
    removeCodeConfigFromGroupMutation,
    {
      variables: {
        tenantId,
        id: configId,
      },
      optimisticResponse: () => ({
        updateUserGroupCodeConfigsByPk: {
          __typename: 'UserGroupCodeConfigs' as const,
          id: configId,
        },
      }),
      update: () => {
        cacheUtils.evict({
          typeName: 'UserGroupCodeConfigs',
          key: { id: configId },
        });
      },
    },
  );

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <NewButton
            icon={{ name: 'Trash' }}
            onClick={() => removeConfigFromGroup()}
            type="outline"
          />
        </TooltipTrigger>
        <TooltipContent>Remove code from this group</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

const cols = new ColDefBuilder<Row>()
  .defaultSortable('code', {
    cellRenderer: (row) => (
      <div className="ml-2">
        <MedicalCodeTag code={row.code} codeType={row.codeType} />
      </div>
    ),
  })
  .defaultSortable('label')
  .defaultSortable('price', {
    label: 'Price',
    cellRenderer: (row) => {
      return `$${row.price}`;
    },
  })
  .defaultSortable('modifiers', {
    label: 'Modifiers',
    cellRenderer: (row) => {
      return row.modifiers.join(', ');
    },
  })
  .colDef({
    accessorKey: 'actions',
    header: '',
    cell: (row) => <DeleteUserGroupCodeConfigCell {...row.row.original} />,
  })
  .build();

type SelectedCode = {
  id: string;
  code: string;
  codeType: MedicalCodeTypesEnum;
  description: string | null;
} | null;

const userGroupConfigFormSchema = z.object({
  label: z.string().optional(),
  price: currencyStringToCentsSchema,
  modifiers: billingCodeModifiersSchema.optional(),
});

const defaultFormValues = {
  label: '',
  price: NaN,
  modifiers: '',
};

type UserGroupConfigForm = z.infer<typeof userGroupConfigFormSchema>;

const AddUserGroupConfigModal: React.FC<{
  userGroupId: string;
  dialog: UseDialog;
  code: SelectedCode;
}> = ({ dialog: { isDialogOpen, toggleDialog }, userGroupId, code }) => {
  const form = useForm<UserGroupConfigForm>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(userGroupConfigFormSchema),
  });

  const [createUserGroup] = useMutation(createUserGroupCodeConfigMutation, {
    onCompleted: () => {
      toggleDialog();
      form.reset(defaultFormValues);
    },
    onError: () => toast.error('Failed to create user group config'),
    optimisticResponse: ({ price, label, modifiers }) => ({
      insertUserGroupCodeConfigsOne: {
        __typename: 'UserGroupCodeConfigs' as const,
        id: 'optimistic',
        price,
        label: label ?? null,
        modifiers: modifiers ?? null,
      },
    }),
    update: (_cache, { data }) => {
      cacheUtils.updateFragment(
        {
          fragment: userGroupConfigsFragment,
          key: {
            id: userGroupId,
          },
        },
        (existing) => {
          const newConfig = data?.insertUserGroupCodeConfigsOne;
          if (!code || !existing || !newConfig) {
            return existing;
          }

          const { id, price, label, modifiers } = newConfig;

          return produce(existing, (draft) => {
            draft.configs.push({
              __typename: 'UserGroupCodeConfigs' as const,
              id,
              price,
              label,
              modifiers,
              medical_code: {
                __typename: 'MedicalCodes' as const,
                id: code.id,
                code: code.code,
                codeType: code.codeType,
              },
            });
          });
        },
      );
    },
  });

  const handleOpenChange = () => {
    toggleDialog();
    form.reset(defaultFormValues);
  };

  const onSubmit = async ({ price, label, modifiers }: UserGroupConfigForm) => {
    if (!code) {
      return;
    }

    createUserGroup({
      variables: {
        userGroupId,
        label: label ? label : code.description,
        price,
        modifiers:
          modifiers && modifiers.length > 0 ? modifiers.split(',') : null,
        codeId: code.id,
      },
    });
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={handleOpenChange}>
      <Form {...form}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>New Billing Code Configuration</DialogTitle>
          </DialogHeader>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <VStack className="my-4" gap={4}>
              <FormField
                control={form.control}
                name="label"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Custom Label</FormLabel>
                    <FormControl>
                      <Input
                        placeholder={code?.description ?? 'Custom Label'}
                        {...field}
                      />
                    </FormControl>
                    <FormDescription>
                      If not provided, the existing label is used.
                    </FormDescription>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="price"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Price (Required)</FormLabel>
                    <FormControl>
                      <CurrencyInput
                        {...field}
                        value={
                          isNaN(field.value)
                            ? ''
                            : field.value?.toString() ?? ''
                        }
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="modifiers"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Modifiers</FormLabel>
                    <FormControl>
                      <Input {...field} value={field.value ?? ''} />
                    </FormControl>
                    <FormDescription>
                      A comma separated list of modifiers.
                    </FormDescription>
                  </FormItem>
                )}
              />
            </VStack>
            <DialogFooter className="mt-2">
              <NewButton submit text="Create" />
            </DialogFooter>
          </form>
        </DialogContent>
      </Form>
    </Dialog>
  );
};

export const UserGroupConfigsPage: React.FC<{}> = () => {
  const tenantId = useAssignedTenantIdFromParams();
  const { userGroupId } = useParams() as { userGroupId: string };
  const dialog = useDialog();
  const [selectedCode, setSelectedCode] = useState<SelectedCode>(null);

  const searchMedicalCodes = useSearchAllMedicalCodes({ codeTypes: ['CPT'] });

  useSuspenseQuery(getUserGroupConfigsQuery, {
    variables: {
      tenantId,
      userGroupId,
    },
  });

  const { configs } = useCompleteFragment({
    fragment: userGroupConfigsFragment,
    key: {
      id: userGroupId,
    },
  });

  const rows = useMemo(() => {
    return (configs ?? []).map<Row>((c) => {
      return {
        tenantId,
        userGroupId,
        configId: c.id,
        label: c.label ?? '',
        code: c.medical_code.code,
        codeType: c.medical_code.codeType,
        price: isNil(c.price) ? 0 : c.price / 100,
        modifiers: (c.modifiers as string[]) ?? [],
      };
    });
  }, [configs, tenantId, userGroupId]);

  return (
    <VStack gap={5}>
      <SearchableBillingCodes
        searchCodes={searchMedicalCodes}
        onCodeAdded={(code) => {
          setSelectedCode(code);
          dialog.openDialog();
        }}
        selectedCodes={[]}
        shouldRenderValue={false}
      />
      <AddUserGroupConfigModal
        userGroupId={userGroupId}
        dialog={dialog}
        code={selectedCode}
      />
      <DataTable
        columns={cols}
        data={rows}
        isCompact
        placeholder="No billing codes added yet."
      />
    </VStack>
  );
};
