import { Text, View } from '@react-pdf/renderer';
import startsWith from 'lodash/startsWith';
import Markdown from 'markdown-to-jsx';
// eslint-disable-next-line no-restricted-imports
import { tv } from 'tailwind-variants';

import { MarkdownOptions } from '../pdf-rendering';
import { tw } from '../pdf-tw';
import { commonStyles } from '../styles';

const styles = tv({
  slots: {
    checkboxRow: 'flex-row',
    checkboxSymbol: 'text-sm font-normal',
    noContent: 'text-sm font-normal text-brandGray500',
    labelValuePair: 'flex flex-row',
    page: 'px-4 py-8 font-[Inter]',
    emptyContent: 'rounded bg-brandGray100 p-3',
    doctorText: 'text-sm font-normal text-brandGray700',
    emptyText: 'text-center text-sm font-normal text-brandGray500',
  },
})();

export const PdfParagraph: React.FC<{ paragraph: string }> = ({
  paragraph,
}) => {
  const leadingSpaces = paragraph.match(/^\s*/)?.[0]?.length ?? 0;
  const isIndented = leadingSpaces >= 2;

  const isBulletPoint =
    paragraph.trim().startsWith('-') ||
    paragraph.trim().startsWith('•') ||
    (paragraph.trim().startsWith('*') && !paragraph.trim().startsWith('**'));

  const isAmendmentHeader =
    paragraph.trim().startsWith('--') || paragraph.trim().startsWith('\\--');

  const isLabelValuePair =
    paragraph.includes('**') &&
    paragraph.includes('**: ') &&
    startsWith(paragraph.trim(), '**');

  if (isAmendmentHeader) {
    let processedText = paragraph.trim();
    if (processedText.startsWith('\\--')) {
      processedText = processedText.substring(1);
    }

    return (
      <View style={tw('mt-4 mb-2')}>
        <Text style={tw(commonStyles.amendmentHeader())}>{processedText}</Text>
      </View>
    );
  }

  if (isLabelValuePair) {
    const parts = paragraph.trim().split('**: ');
    const part0 = parts[0] ?? '**';
    const part1 = parts[1] ?? '';
    const label = part0.substring(Math.min(2, part0.length));
    const value = part1;
    return (
      <View style={tw(styles.labelValuePair())}>
        <Text style={tw(commonStyles.boldText())}>{label}: </Text>
        <Text style={tw(commonStyles.text())}>{value}</Text>
      </View>
    );
  }

  // Only handle indented bullet points specially
  if (isIndented && isBulletPoint) {
    let processedText = paragraph.trim();
    processedText = processedText.substring(1).trim();

    if (
      processedText.includes('**') ||
      processedText.includes('#') ||
      processedText.includes('_')
    ) {
      return (
        <View style={tw(commonStyles.nestedBulletItem())}>
          <Text style={tw(commonStyles.bullet())}>•</Text>
          <View style={tw('flex-1')}>
            <Markdown options={MarkdownOptions}>{processedText}</Markdown>
          </View>
        </View>
      );
    }

    return (
      <View style={tw(commonStyles.nestedBulletItem())}>
        <Text style={tw(commonStyles.bullet())}>•</Text>
        <Text style={tw(commonStyles.text())}>{processedText}</Text>
      </View>
    );
  }

  // If it's plain text without any markdown formatting, wrap it in a Text component directly
  if (
    !paragraph.includes('**') &&
    !paragraph.includes('#') &&
    !paragraph.includes('_') &&
    !paragraph.includes('*') &&
    !paragraph.includes('-')
  ) {
    return <Text style={tw(commonStyles.text())}>{paragraph}</Text>;
  }

  // Otherwise use Markdown component for formatted text
  return <Markdown options={MarkdownOptions}>{paragraph}</Markdown>;
};
