import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { userFileTypesSchema } from '@eluve/graphql-types';

const c = initContract();

export const usersContract = c.router(
  {
    /**
     * Prepare a user file upload by creating the necessary database records
     * to track the attempt and then returning a presigned upload URL
     */
    prepareUserFileUpload: {
      method: 'POST',
      path: '/file-upload',
      body: z.object({
        extension: z.string(),
        fileType: userFileTypesSchema,
      }),
      responses: {
        200: z.object({
          userUploadId: z.string(),
          uploadUrl: z.string(),
        }),
      },
    },
    /**
     * Mark a file upload that we previously prepared as complete once the
     * file has been succesfully uploaded to GCS
     */
    markUserFileUploadComplete: {
      method: 'POST',
      path: '/file-upload/complete',
      body: z.object({
        userUploadId: z.string().uuid(),
      }),
      responses: {
        200: z.object({}),
      },
    },
    /**
     * Mark a file upload that we previously prepared as failed when the
     * file could not be uploaded to GCS
     */
    markUserFileUploadFailed: {
      method: 'POST',
      path: '/file-upload/failed',
      body: z.object({
        userUploadId: z.string().uuid(),
      }),
      responses: {
        200: z.object({}),
      },
    },
    /**
     * Mark a file upload that we previously completed as deleted
     * as a way of soft deleting the user file
     */
    markUserFileUploadDeleted: {
      method: 'DELETE',
      path: '/file-upload/complete',
      body: z.object({
        userUploadId: z.string().uuid(),
      }),
      responses: {
        204: z.custom<undefined>(),
      },
    },
    /**
     * Requests a presigned GCS Url for downloading a user file
     */
    getUserFileSignedUrl: {
      method: 'GET',
      path: '/:userId/file/:fileId',
      responses: {
        200: z.object({
          presignedUrl: z.string().nullable(),
        }),
      },
    },
  },
  {
    pathPrefix: '/:tenantId/users',
  },
);
