import { PlusIcon } from 'lucide-react';

import { AvatarWithInitials, TooltipLabel } from '@eluve/blocks';
import { Badge, Box } from '@eluve/components';

import { PatientOptionInfo, externalPatientID, getName } from './utils';

export const PatientOption: React.FC<PatientOptionInfo & { new?: boolean }> = (
  patient,
) => {
  const name = getName(patient);
  const externalPatientIds = externalPatientID(patient);

  return (
    <Box hStack className="gap-4 py-1">
      <AvatarWithInitials name={name} id={patient.id} />
      <Box vStack className="w-full flex-1">
        <span className="font-semibold text-gray-11 privacy-text">{name}</span>
        {externalPatientIds && (
          <TooltipLabel label={`External Patient Id: ${externalPatientIds}`}>
            <span className="max-w-[200px] text-ellipsis rounded-lg bg-gray-3 px-2 py-1 text-xs text-gray-10">
              ID: {externalPatientIds}
            </span>
          </TooltipLabel>
        )}
        {patient.new && (
          <Badge className="w-max">
            <PlusIcon className="mr-1 size-4" />
            Create New
          </Badge>
        )}
      </Box>
    </Box>
  );
};
