import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import * as React from 'react';

import { Icon } from './Icon';
import { cn } from './cn';

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      'peer size-4 shrink-0 rounded-sm border border-brandGray300 text-brandGray900 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-brandGray900 data-[state=checked]:bg-brandGray900 data-[state=checked]:text-brandGray100',
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn('-mt-px flex items-center justify-center')}
    >
      <Icon
        name={props.checked === 'indeterminate' ? 'Minus' : 'Check'}
        size="xs"
      />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
