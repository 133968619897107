import partition from 'lodash/partition';

import type { MedicalCodeTypesEnum } from '@eluve/graphql-types';

/*
 * Generates a Markdown formatted string for billing codes. Sampe output:
 * # Code Type
 * - [code1]: [description]
 * - [code2]: [description]
 */
export const billingCodesToMarkdown = (
  billingCodes: Array<{
    medical_code?: {
      code: string;
      description: string | null;
      codeType: MedicalCodeTypesEnum;
    } | null;
  }>,
) => {
  if (!billingCodes || billingCodes.length === 0) {
    return '';
  }
  // Group codes based on the diagnosis/procedure type
  const [diagnosisCodes, procedureCodes] = partition(
    billingCodes,
    (code) =>
      code?.medical_code?.codeType &&
      ['ICD_10', 'ICD_9', 'SNOMED'].includes(code.medical_code.codeType),
  );

  const diagnosisItems = diagnosisCodes
    .map(
      ({ medical_code }) =>
        `- ${medical_code?.code}: ${medical_code?.description}`,
    )
    .join('\n');
  const procedureItems = procedureCodes
    .map(
      ({ medical_code }) =>
        `- ${medical_code?.code}: ${medical_code?.description}`,
    )
    .join('\n');

  const diagnosisSection = diagnosisItems
    ? `# Diagnosis Codes\n${diagnosisItems}`
    : '';
  const procedureSection = procedureItems
    ? `# Procedure Codes\n${procedureItems}`
    : '';

  return [diagnosisSection, procedureSection].filter(Boolean).join('\n\n');
};
