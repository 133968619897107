import { BubbleMenu, BubbleMenuProps, isNodeSelection } from '@tiptap/react';
import {
  BoldIcon,
  ItalicIcon,
  StrikethroughIcon,
  UnderlineIcon,
} from 'lucide-react';
import { FC, useState } from 'react';

import { cn } from '../../utils';

import { NodeSelector } from './node-selector';

export interface BubbleMenuItem {
  name: string;
  isActive: () => boolean;
  command: () => void;
  icon: typeof BoldIcon;
}

type EditorBubbleMenuProps = Omit<BubbleMenuProps, 'children'>;

export const EditorBubbleMenu: FC<EditorBubbleMenuProps> = (props) => {
  const { editor } = props;

  const [isNodeSelectorOpen, setIsNodeSelectorOpen] = useState(false);
  // const [isColorSelectorOpen, setIsColorSelectorOpen] = useState(false);

  // do not show BubbleMenu if editor is not editable
  if (!editor?.isEditable) {
    return null;
  }

  const items: BubbleMenuItem[] = [
    {
      name: 'bold',
      isActive: () => editor?.isActive('bold') ?? false,
      command: () => editor?.chain().focus().toggleBold().run(),
      icon: BoldIcon,
    },
    {
      name: 'italic',
      isActive: () => editor?.isActive('italic') ?? false,
      command: () => editor?.chain().focus().toggleItalic().run(),
      icon: ItalicIcon,
    },
    {
      name: 'underline',
      isActive: () => editor?.isActive('underline') ?? false,
      command: () => editor?.chain().focus().toggleUnderline().run(),
      icon: UnderlineIcon,
    },
    {
      name: 'strike',
      isActive: () => editor?.isActive('strike') ?? false,
      command: () => editor?.chain().focus().toggleStrike().run(),
      icon: StrikethroughIcon,
    },
  ];

  const bubbleMenuProps: EditorBubbleMenuProps = {
    ...props,
    shouldShow: ({ state, editor }) => {
      const { selection } = state;
      const { empty } = selection;

      // don't show bubble menu if:
      // - the selection is empty
      // - the selection is a node selection (for drag handles)
      if (empty || isNodeSelection(selection)) {
        return false;
      }
      return true;
    },
    tippyOptions: {
      moveTransition: 'transform 0.15s ease-out',
      onHidden: () => {
        setIsNodeSelectorOpen(false);
        // setIsColorSelectorOpen(false);
      },
    },
  };

  return (
    // BubbleMenu must be surrounded by div https://github.com/ueberdosis/tiptap/issues/2658
    <div>
      <BubbleMenu
        {...bubbleMenuProps}
        className="flex w-fit divide-x divide-brandGray200 rounded border border-brandGray200 bg-white shadow-xl"
      >
        <NodeSelector
          editor={editor!}
          isOpen={isNodeSelectorOpen}
          setIsOpen={() => {
            setIsNodeSelectorOpen(!isNodeSelectorOpen);
            // setIsColorSelectorOpen(false);
          }}
        />

        <div className="flex">
          {items.map((item, index) => (
            <button
              key={index}
              onClick={item.command}
              className="p-2 text-contentTertiary hover:bg-brandGray100 active:bg-brandGray200"
              type="button"
            >
              <item.icon
                className={cn('size-4', {
                  'text-purple': item.isActive(),
                })}
              />
            </button>
          ))}
        </div>
        {/*  uncomment if we want to enable color

      <ColorSelector
        editor={editor!}
        isOpen={isColorSelectorOpen}
        setIsOpen={() => {
          setIsColorSelectorOpen(!isColorSelectorOpen);
          setIsNodeSelectorOpen(false);
        }}
      /> */}
      </BubbleMenu>
    </div>
  );
};
