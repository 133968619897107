import { useMutation, useQuery } from '@apollo/client';
import { produce } from 'immer';
import { useEffect } from 'react';
import { v4 } from 'uuid';

import { cacheUtils } from '@eluve/apollo-client';
import { toast } from '@eluve/components';
import {
  useAppointmentActivity,
  useAppointmentMode,
} from '@eluve/frontend-appointment-hooks';
import { DbEnums } from '@eluve/graphql-types';
import { FragmentOf } from '@eluve/graphql.tada';
import { usePrevious } from '@eluve/utility-hooks';

import {
  addAppointmentBillingCodeMutation,
  appointmentBillingCodeRecommendationsFragment,
  appointmentBillingCodesFragment,
  getAppointmentBillingCodesQuery,
  medicalCodeFragment,
  removeBillingCodeMutation,
} from './AppointmentBillingCodes.operations';

export type MedicalCodeType = Omit<
  FragmentOf<typeof medicalCodeFragment>,
  '__typename'
> & {
  price?: number | null;
  modifiers?: string[] | null;
  userGroupCodeConfigId?: string | null;
};

export const useEditBillingCodes = ({
  tenantId,
  appointmentId,
}: {
  tenantId: string;
  appointmentId: string;
}) => {
  const { appointmentLlmJobs } = useAppointmentActivity();

  const isAudioCopilotChart = useAppointmentMode() === 'AUDIO_COPILOT';

  const billingCodeJob = appointmentLlmJobs.find(
    (job) => job.llmOutputType === 'BILLING_CODE_RECOMMENDATION',
  );

  const billingCodesJobStatus = billingCodeJob?.jobStatus;

  const previousBillingCodesJobStatus = usePrevious(billingCodesJobStatus);

  const { data, refetch } = useQuery(getAppointmentBillingCodesQuery, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  // If a recommendation job finishes since we fetched, we should trigger a refetch
  useEffect(
    function refetchManualChartCodeRecommendationsOnJobCompletion() {
      if (
        previousBillingCodesJobStatus === DbEnums.WorkerStatus.IN_PROGRESS &&
        billingCodesJobStatus === DbEnums.WorkerStatus.COMPLETED
      ) {
        refetch();
      }
    },
    [
      refetch,
      isAudioCopilotChart,
      billingCodesJobStatus,
      previousBillingCodesJobStatus,
    ],
  );

  const billingCodes = data?.appointmentsByPk?.billingCodes ?? [];
  const recommendations =
    data?.appointmentsByPk?.billing_code_recommendations ?? [];

  const [removeBillingCode] = useMutation(removeBillingCodeMutation, {
    onError: () => toast.error('Failed to remove billing code'),
  });

  const [addBillingCode] = useMutation(addAppointmentBillingCodeMutation, {
    onError: () => toast.error('Failed to add billing code'),
  });

  const onRemoveBillingCode = async ({
    medicalCode,
    tenantMedicalCode,
  }: {
    medicalCode?: MedicalCodeType;
    tenantMedicalCode?: MedicalCodeType;
  }) => {
    const billingCode = billingCodes.find((billingCode) => {
      return (
        (medicalCode?.id && billingCode.medical_code?.id === medicalCode.id) ||
        (tenantMedicalCode?.id &&
          billingCode.tenant_medical_code?.id === tenantMedicalCode.id)
      );
    });
    if (!billingCode) {
      toast.error('Could not find the billing code to remove');
      return;
    }

    const billingCodeRecommendationIdToUpdate = recommendations.find((code) =>
      medicalCode
        ? code.medical_code?.id === medicalCode.id
        : tenantMedicalCode
          ? code.tenant_medical_code?.id === tenantMedicalCode.id
          : false,
    )?.id;

    await removeBillingCode({
      variables: {
        appointmentBillingCodeId: billingCode.id,
        appointmentBillingCodeRecommendationId:
          billingCodeRecommendationIdToUpdate ?? null,
        skipUpdate: !billingCodeRecommendationIdToUpdate,
      },
      optimisticResponse: () => {
        return {
          deleteAppointmentBillingCodesByPk: {
            __typename: 'AppointmentBillingCodes' as const,
            id: billingCode.id,
          },
          updateAppointmentBillingCodeRecommendations: {
            returning: billingCodeRecommendationIdToUpdate
              ? [
                  {
                    id: billingCodeRecommendationIdToUpdate,
                    __typename:
                      'AppointmentBillingCodeRecommendations' as const,
                  },
                ]
              : [],
          },
        };
      },
      update: (_cache) => {
        if (billingCodeRecommendationIdToUpdate) {
          cacheUtils.updateFragment(
            {
              fragment: appointmentBillingCodeRecommendationsFragment,
              key: {
                id: appointmentId,
              },
            },
            (existing) => {
              if (!existing) {
                return existing;
              }
              return produce(existing, (draft) => {
                const index = draft.billing_code_recommendations.findIndex(
                  (rec) => rec.id === billingCodeRecommendationIdToUpdate,
                );
                if (index !== -1 && draft.billing_code_recommendations[index]) {
                  draft.billing_code_recommendations[index].isFinal = false;
                }
              });
            },
          );
        }

        cacheUtils.updateFragment(
          {
            fragment: appointmentBillingCodesFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            if (!existing) {
              return existing;
            }
            // Optimistically remove the billing code from the list
            return produce(existing, (draft) => {
              if (medicalCode) {
                const idx = draft.billingCodes.findIndex(
                  (c) => c.medical_code?.id === medicalCode.id,
                );
                if (idx !== -1) {
                  draft.billingCodes.splice(idx, 1);
                }
              }
              if (tenantMedicalCode) {
                const idx = draft.billingCodes.findIndex(
                  (c) => c.tenant_medical_code?.id === tenantMedicalCode.id,
                );
                if (idx !== -1) {
                  draft.billingCodes.splice(idx, 1);
                }
              }
            });
          },
        );
      },
    });
  };

  const onAddBillingCode = async ({
    medicalCode,
    tenantMedicalCode,
  }: {
    medicalCode?: MedicalCodeType & { userGroupCodeConfigId: string | null };
    tenantMedicalCode?: MedicalCodeType;
  }) => {
    await addBillingCode({
      variables: {
        appointmentId,
        billingCodeId: medicalCode?.id ?? null,
        tenantCodeId: tenantMedicalCode?.id ?? null,
        price: medicalCode?.price ?? tenantMedicalCode?.price ?? null,
        quantity: 1,
        modifiers:
          medicalCode?.modifiers ?? tenantMedicalCode?.modifiers ?? null,
        userGroupCodeConfigId: medicalCode?.userGroupCodeConfigId ?? null,
      },
      optimisticResponse: ({ price, quantity, modifiers }) => ({
        insertAppointmentBillingCodesOne: {
          __typename: 'AppointmentBillingCodes' as const,
          id: v4(),
          price: price ?? null,
          quantity: quantity ?? null,
          description:
            tenantMedicalCode?.description ?? medicalCode?.description ?? null,
          modifiers: modifiers ?? null,
        },
      }),
      update: (_cache, { data }) => {
        cacheUtils.updateFragment(
          {
            fragment: appointmentBillingCodesFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            const updatedId = data?.insertAppointmentBillingCodesOne?.id;
            if (!existing || !updatedId) {
              return existing;
            }

            // Optimistically add the billing code to the list of accepted billing codes
            return produce(existing, (draft) => {
              draft.billingCodes.push({
                __typename: 'AppointmentBillingCodes',
                id: updatedId,
                price: data?.insertAppointmentBillingCodesOne?.price ?? null,
                quantity: 1,
                modifiers:
                  data?.insertAppointmentBillingCodesOne?.modifiers ?? null,
                linked_codes: [],
                description:
                  data?.insertAppointmentBillingCodesOne?.description ?? null,
                medical_code: medicalCode
                  ? {
                      __typename: 'MedicalCodes',
                      ...medicalCode,
                    }
                  : null,
                tenant_medical_code: tenantMedicalCode
                  ? {
                      __typename: 'TenantMedicalCodes',
                      ...tenantMedicalCode,
                    }
                  : null,
              });
            });
          },
        );
      },
    });
  };

  return {
    billingCodes,
    refetchBillingCodes: refetch,
    recommendations,
    onRemoveBillingCode,
    onAddBillingCode,
    tenantMedicalCodesExist: data?.tenantsByPk?.tenantMedicalCodesExist,
  };
};
