import { Text, View } from '@react-pdf/renderer';

import { tw } from '../pdf-tw';
import { commonStyles } from '../styles';
import { PdfAmendment } from '../types';
import { getAmendmentHeader } from '../utils/amendment-header';

import { PdfText } from './PdfText';

const findChartAmendmentsForBlock = (
  blockPath: string,
  amendments: PdfAmendment[],
) => {
  const matchingAmendments: {
    user: { firstName: string; lastName: string; email: string };
    submittedAt: string | Date;
    value: string;
  }[] = [];

  amendments.forEach((amendment) => {
    amendment.chartAmendments.forEach((chartAmendment) => {
      if (chartAmendment.keyPath && chartAmendment.value) {
        const keyPathParts = chartAmendment.keyPath.split('.');
        const blockPathParts = blockPath.split('.');

        if (
          chartAmendment.keyPath === blockPath ||
          (blockPathParts.length > 0 && keyPathParts[0] === blockPathParts[0])
        ) {
          matchingAmendments.push({
            user: amendment.user,
            submittedAt: amendment.submittedAt,
            value: chartAmendment.value,
          });
        }
      }
    });
  });

  return matchingAmendments;
};

export const TextAmendments: React.FC<{
  blockPath: string;
  amendments: PdfAmendment[];
}> = ({ blockPath, amendments }) => {
  const matchingAmendments = findChartAmendmentsForBlock(blockPath, amendments);
  if (matchingAmendments.length === 0) return null;

  return (
    <View>
      {matchingAmendments.map((amendment, index) => {
        const amendmentHeader = getAmendmentHeader({
          ...amendment.user,
          submittedAt: amendment.submittedAt,
        });

        return (
          <View key={index} style={tw(commonStyles.amendment())}>
            <Text style={tw(commonStyles.amendmentHeader())}>
              {amendmentHeader}
            </Text>
            <View>
              <PdfText text={amendment.value} />
            </View>
          </View>
        );
      })}
    </View>
  );
};
