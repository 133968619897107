import getInitials from 'initials';
import { useMemo } from 'react';

import { HStack } from './HStack';
import { Icon, IconProps } from './Icon';
import { VStack } from './VStack';
import { textStyles } from './textStyles';
import { P } from './typography';

export const TenantCard = ({
  icon,
  tenantName,
  userDisplayName,
}: {
  icon?: {
    name: IconProps['name'];
  };
  tenantName: string;
  userDisplayName: string;
}) => {
  const initials = useMemo(() => getInitials(tenantName), [tenantName]);

  return (
    <HStack className="shrink-0" align="center">
      <div
        className={textStyles.body({
          weight: 'semibold',
          className:
            'flex size-11 shrink-0 justify-center rounded-lg bg-brandGray800 pt-2.5 uppercase text-brandGray200',
        })}
      >
        {initials}
      </div>
      <VStack className="min-w-0" gap={0} align="stretch">
        <P
          className={textStyles.body({
            size: 'm',
            weight: 'semibold',
            className: 'truncate',
          })}
        >
          {tenantName}
        </P>
        <P
          className={textStyles.body({
            color: 'tertiary',
            size: 's',
            className: 'truncate',
          })}
        >
          {userDisplayName}
        </P>
      </VStack>
      {icon && (
        <Icon
          className="shrink-0 text-contentTertiary"
          name={icon.name}
          size="sm"
        />
      )}
    </HStack>
  );
};
