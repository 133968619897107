import { ReloadIcon } from '@radix-ui/react-icons';
import { CloudUploadIcon } from 'lucide-react';
import { usePostHog } from 'posthog-js/react';
import { useMount } from 'react-use';

import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  P,
} from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';

import {
  useAppointmentTasksActor,
  useAppointmentTasksSelector,
} from './AppointmentTasksMachineProvider';

const ErrorDialog: React.FC<{ fileId: string }> = ({ fileId }) => {
  const postHog = usePostHog();
  const { send } = useAppointmentTasksActor();

  const logger = useNamedLogger('GCSFileUploadFailedDialog');

  useMount(() => {
    postHog.capture('file_upload_failed_dialog_shown');
    logger.warn('GCSFileUploadFailedDialog shown');
  });

  return (
    <Dialog
      defaultOpen={true}
      onOpenChange={(open) => {
        if (!open) {
          send({ type: 'CANCEL_UPLOAD', fileId });
        }
      }}
    >
      <DialogContent
        overlay={false}
        onInteractOutside={(e) => e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle className="text-destructive">
            <CloudUploadIcon className="mr-4 inline-flex size-6" />
            Session audio recording backup failed
          </DialogTitle>
          <DialogDescription>
            We are unable to backup your session audio recording to the cloud
            storage. Please try again.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              variant="secondary"
              onClick={() => {
                logger.info('User clicked cancel for upload failed dialog');
                send({ type: 'CANCEL_UPLOAD', fileId });
              }}
            >
              Cancel
            </Button>
          </DialogClose>
          <Button
            onClick={() => {
              logger.info('User clicked retry for upload failed dialog');
              send({ type: 'RETRY' });
            }}
          >
            <div className="flex flex-row">
              <ReloadIcon className="mr-2 size-4" />
              <P>Try again</P>
            </div>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export const GCSFileUploadFailedDialog = () => {
  const hasFailed = useAppointmentTasksSelector(
    (s) =>
      s.can({ type: 'RETRY' }) &&
      s.matches({ InProgress: 'UploadFile' }) &&
      s.context.activeTask?.type === 'TASK.UPLOAD_FILE' &&
      s.context.activeTask.isBackgroundUpload === false,
  );

  const fileId = useAppointmentTasksSelector((s) => {
    if (s.context.activeTask?.type === 'TASK.UPLOAD_FILE') {
      return s.context.activeTask.fileName;
    }

    return undefined;
  });

  if (!hasFailed || !fileId) {
    return null;
  }

  return <ErrorDialog fileId={fileId} />;
};
