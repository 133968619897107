import React from 'react';

import { useCompleteFragment } from '@eluve/apollo-client';
import { MedicalCode } from '@eluve/blocks';
import { AmendmentHeader, HStack, VStack } from '@eluve/components';
import {
  amendmentBaseDetailsFragment,
  billingCodeAmendmentFragment,
} from '@eluve/frontend-appointment-hooks';
import { useUserName } from '@eluve/smart-blocks';

export interface AppointmentBillingCodesAmendmentProps {
  billingCodeAmendmentId: string;
  amendmentId: string;
  disabled?: boolean;
}

export const AppointmentBillingCodesAmendment: React.FC<
  AppointmentBillingCodesAmendmentProps
> = ({ billingCodeAmendmentId, amendmentId, disabled = true }) => {
  const billingCodeAmendmentData = useCompleteFragment({
    fragment: billingCodeAmendmentFragment,
    key: { id: billingCodeAmendmentId },
  });

  const amendmentData = useCompleteFragment({
    fragment: amendmentBaseDetailsFragment,
    key: { id: amendmentId },
  });

  const userName = useUserName(amendmentData.userId);

  const hasBillingCodes = billingCodeAmendmentData?.billing_codes.length > 0;

  return (
    <VStack
      gap={6}
      className="rounded-xl border border-brandGray200 bg-white p-6"
    >
      <AmendmentHeader
        date={billingCodeAmendmentData?.createdAt}
        userName={userName}
        isReadonly
      />

      <HStack gap={3} wrap>
        {hasBillingCodes &&
          billingCodeAmendmentData.billing_codes.map((code) => (
            <MedicalCode
              key={code.id}
              code={
                (code.tenant_medical_code?.code ?? code.medical_code?.code)!
              }
              codeType={
                (code.tenant_medical_code?.codeType ??
                  code.medical_code?.codeType)!
              }
              description={code.description ?? ''}
              disabled={disabled}
            />
          ))}

        {!hasBillingCodes && (
          <div className="text-brandGray400">No billing codes.</div>
        )}
      </HStack>
    </VStack>
  );
};
