import { z } from 'zod';

import { MatchingRecord } from '@eluve/utils';

import { UserFileTypesEnum } from './types';

export const UserFileTypesLookup = {
  SIGNATURE: 'SIGNATURE',
} as const satisfies MatchingRecord<UserFileTypesEnum>;

export const userFileTypesSchema = z.nativeEnum(UserFileTypesLookup);
