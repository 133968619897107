import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { MedicalCode, MedicalCodeTag, TooltipLabel } from '@eluve/blocks';
import {
  Checkbox,
  CurrencyInput,
  Dialog,
  DialogContent,
  DialogFooter,
  Divider,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  HStack,
  Icon,
  Input,
  NewButton,
  P,
  VStack,
  cn,
  textStyles,
} from '@eluve/components';
import { useDialog } from '@eluve/utility-hooks';
import { billingCodeModifiersSchema } from '@eluve/utils';

import {
  AppointmentMedicalCode,
  useAppointmentBillingCodes,
} from './useAppointmentBillingCodes';

// Private Components

interface ModifiersControlProps {
  medicalCode: AppointmentMedicalCode;
  onChange: (value: string[] | null) => void;
  modifiers: string[] | null;
}

const modifiersFormSchema = z.object({
  modifiers: billingCodeModifiersSchema.optional(),
});

type ModifiersForm = z.infer<typeof modifiersFormSchema>;

const ModifiersControl: React.FC<ModifiersControlProps> = ({
  medicalCode,
  onChange,
  modifiers,
}) => {
  const { isDialogOpen: isOpen, toggleDialog: toggle } = useDialog(false);

  const form = useForm<ModifiersForm>({
    defaultValues: { modifiers: (modifiers ?? []).join(',') },
    resolver: zodResolver(modifiersFormSchema),
  });

  useEffect(() => {
    form.setValue('modifiers', (modifiers ?? []).join(','));
  }, [form, modifiers]);

  const handleOpenChange = () => {
    toggle();
    form.reset({ modifiers: (modifiers ?? []).join(',') });
  };

  const onSubmit = async ({ modifiers }: ModifiersForm) => {
    onChange(modifiers && modifiers.length > 0 ? modifiers.split(',') : null);
    toggle();
  };

  const hasModifiers = modifiers && modifiers.length > 0;

  return (
    <HStack
      className={cn(
        'rounded-lg border border-brandGray200 p-1',
        hasModifiers && 'pl-4',
      )}
      gap={1}
      inline
      wFull={false}
    >
      {hasModifiers && (
        <div
          className={textStyles.body({
            color: 'primary',
            size: 's',
            className: 'text-nowrap',
          })}
        >
          {modifiers?.join(', ')}
        </div>
      )}
      <TooltipLabel label="Edit Modifiers">
        <NewButton
          icon={{ name: 'Pencil' }}
          onClick={toggle}
          spacing="compact"
          type="ghost"
        />
      </TooltipLabel>
      <Dialog open={isOpen} onOpenChange={handleOpenChange}>
        <Form {...form}>
          <DialogContent className="max-w-sm pt-9">
            <form
              className="flex flex-col gap-4"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <VStack align="center">
                <MedicalCodeTag
                  code={medicalCode.code}
                  codeType={medicalCode.codeType}
                />
                <P className="text-center">{medicalCode.description}</P>
              </VStack>
              <FormField
                control={form.control}
                name="modifiers"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormControl>
                      <Input {...field} value={field.value ?? ''} />
                    </FormControl>
                    <FormDescription className="text-center">
                      A comma separated list of modifiers.
                    </FormDescription>
                  </FormItem>
                )}
              />
              <DialogFooter className="flex w-full flex-col sm:justify-center">
                <NewButton submit text="Update Modifiers" />
              </DialogFooter>
            </form>
          </DialogContent>
        </Form>
      </Dialog>
    </HStack>
  );
};

interface PriceControlProps {
  medicalCode: AppointmentMedicalCode;
  onChange: (value: number) => void;
  price: number;
}

const PriceControl: React.FC<PriceControlProps> = ({
  medicalCode,
  onChange,
  price,
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string | undefined>(
    ((price ?? 0) / 100).toFixed(2),
  );

  useEffect(() => {
    setValue(((price ?? 0) / 100).toFixed(2));
  }, [price]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    onChange(Math.floor(Number(value) * 100));
    setOpen(false);
    event.preventDefault();
  };

  return (
    <HStack
      className="rounded-lg border border-brandGray200 p-1 pl-4"
      gap={1}
      inline
      wFull={false}
    >
      <div
        className={textStyles.body({
          size: 's',
          className: 'text-brandGray800',
        })}
      >
        ${((price ?? 0) / 100).toFixed(2)}
      </div>
      <TooltipLabel label="Edit Price">
        <NewButton
          disabled={price == null}
          icon={{ name: 'Pencil' }}
          onClick={() => {
            setOpen(true);
          }}
          spacing="compact"
          type="ghost"
        />
      </TooltipLabel>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="max-w-sm pt-9">
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <VStack align="center">
              <MedicalCodeTag
                code={medicalCode.code}
                codeType={medicalCode.codeType}
              />
              <P className="text-center">{medicalCode.description}</P>
            </VStack>
            <CurrencyInput onChange={setValue} value={value} />
            <DialogFooter className="flex w-full flex-col sm:justify-center">
              <NewButton submit text="Update Price" />
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </HStack>
  );
};

interface QuantityControlProps {
  max?: number;
  min?: number;
  onChange: (value: number) => void;
  value?: number;
}

const QuantityControl: React.FC<QuantityControlProps> = ({
  max,
  min = 0,
  onChange,
  value = 0,
}) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleDecreaseClick = () => {
    const newValue = currentValue - 1;
    setCurrentValue(newValue);
    onChange(newValue);
  };

  const handleIncreaseClick = () => {
    const newValue = currentValue + 1;
    setCurrentValue(newValue);
    onChange(newValue);
  };

  return (
    <HStack
      className="rounded-lg border border-brandGray200 p-1"
      gap={1}
      inline
      wFull={false}
    >
      <NewButton
        disabled={currentValue <= min}
        icon={{ name: 'MinusCircle' }}
        onClick={handleDecreaseClick}
        spacing="compact"
        type="ghost"
      />
      <div
        className={textStyles.body({
          size: 's',
          className: 'min-w-4 select-none text-center text-brandGray800',
        })}
      >
        {currentValue}
      </div>
      <NewButton
        disabled={max != null && currentValue >= max}
        icon={{ name: 'PlusCircle' }}
        onClick={handleIncreaseClick}
        spacing="compact"
        type="ghost"
      />
    </HStack>
  );
};

// Public Components

const textClassName = textStyles.body({ color: 'primary', size: 's' });

export interface AppointmentBillingCodePricesProps {
  appointmentId: string;
  isReadonly?: boolean;
  tenantId: string;
}

export const AppointmentBillingCodePrices: React.FC<
  AppointmentBillingCodePricesProps
> = ({ appointmentId, isReadonly = false, tenantId }) => {
  const {
    billingCodes,
    getQuantity,
    updateLink,
    updateModifiers,
    updatePrice,
    updateQuantity,
  } = useAppointmentBillingCodes({ appointmentId, tenantId });

  const cptCodes = billingCodes.filter(
    (code) => code.medical_code?.codeType === 'CPT',
  );
  const icdCodes = billingCodes.filter(
    (code) => code.medical_code?.codeType === 'ICD_10',
  );

  if (cptCodes.length === 0) {
    return null;
  }

  return (
    <VStack gap={3}>
      <h6
        className={textStyles.body({
          weight: 'semibold',
          size: 's',
          className: 'uppercase text-brandGray600',
        })}
      >
        Billing Details
      </h6>
      {cptCodes.map(
        (cptCode) =>
          cptCode.medical_code && (
            <div
              key={cptCode.id}
              className="w-full rounded-lg border border-borderPrimary bg-white p-2"
            >
              <VStack
                align="center"
                className="sm:flex-row"
                gap={2}
                justify="between"
              >
                <HStack gap={0} inline wFull={false}>
                  <MedicalCode
                    code={cptCode.medical_code.code}
                    codeType={cptCode.medical_code.codeType}
                    description={cptCode?.description ?? ''}
                    type="ghost"
                  />
                  {isReadonly && cptCode.modifiers && (
                    <div className={textClassName}>
                      {cptCode.modifiers.join(', ')}
                    </div>
                  )}
                  {!isReadonly && (
                    <ModifiersControl
                      medicalCode={cptCode.medical_code}
                      modifiers={(cptCode.modifiers as string[]) ?? []}
                      onChange={(value) => {
                        updateModifiers(cptCode.id, value);
                      }}
                    />
                  )}
                </HStack>
                <div className="h-px w-full shrink-0 bg-borderPrimary sm:h-8 sm:w-px" />
                <HStack
                  className="sm:w-auto sm:gap-2"
                  gap={1}
                  inline
                  justify="between"
                >
                  {isReadonly ? (
                    <div className={textClassName}>{getQuantity(cptCode)}</div>
                  ) : (
                    <QuantityControl
                      max={50}
                      min={1}
                      onChange={(value) => {
                        updateQuantity(cptCode.id, value);
                      }}
                      value={getQuantity(cptCode)}
                    />
                  )}
                  <Icon name="X" size="xs" className="text-brandGray400" />
                  {isReadonly ? (
                    <div className={textClassName}>
                      {`$${((cptCode.price ?? 0) / 100).toFixed(2)}`}
                    </div>
                  ) : (
                    <PriceControl
                      medicalCode={cptCode.medical_code}
                      onChange={(value) => {
                        updatePrice(cptCode.id, value);
                      }}
                      price={cptCode.price ?? 0}
                    />
                  )}
                  <Icon name="Equal" size="xs" className="text-brandGray400" />
                  <div className={cn(textClassName, 'pr-1.5 sm:pr-3.5')}>
                    {`$${((getQuantity(cptCode) * (cptCode.price ?? 0)) / 100).toFixed(2)}`}
                  </div>
                </HStack>
              </VStack>
              <Divider />
              {icdCodes.length ? (
                <VStack
                  className="items-stretch sm:flex-row sm:flex-wrap"
                  gap={2}
                >
                  {icdCodes.map((icdCode) => {
                    const isSelected = cptCode.linked_codes.some(
                      (link) =>
                        link.targetAppointmentBillingCodeId === icdCode.id,
                    );

                    if (isReadonly && !isSelected) return null;

                    if (!icdCode.medical_code) return null;

                    return (
                      <MedicalCode
                        key={icdCode.id}
                        code={icdCode.medical_code.code}
                        codeType={icdCode.medical_code.codeType}
                        description={
                          icdCode?.description ??
                          icdCode.medical_code.description ??
                          ''
                        }
                        onClick={
                          isReadonly
                            ? undefined
                            : () =>
                                updateLink(isSelected, cptCode.id, icdCode.id)
                        }
                        startAdornment={
                          isReadonly ? null : (
                            <Checkbox checked={isSelected} className="ml-1" />
                          )
                        }
                        type={isSelected ? 'outline' : 'outlineDashed'}
                      />
                    );
                  })}
                </VStack>
              ) : (
                <VStack align="center" className="p-3">
                  <P className="text-center text-brandGray600">
                    No ICD codes added above yet.
                  </P>
                </VStack>
              )}
            </div>
          ),
      )}
    </VStack>
  );
};
