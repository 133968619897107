import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { MedicalCodeTypesSchema } from '@eluve/graphql-types';

const c = initContract();

const searchCodesResponseSchema = z.object({
  id: z.string(),
  codeType: z.string(),
  code: z.string(),
  price: z.number().nullish(),
  modifiers: z.any().nullish(),
  description: z.string().nullable(),
  userGroupCodeConfigId: z.string().nullable(),
  type: z.union([
    z.literal('medical_codes'),
    z.literal('tenant_medical_codes'),
  ]),
});

export type SearchMedicalCodesResponse = z.infer<
  typeof searchCodesResponseSchema
>;

export const medicalCodesContract = c.router(
  {
    search: {
      method: 'GET',
      path: '/search',
      query: z.object({
        searchTerm: z.string(),
        locationId: z.string().optional(),
        codeTypes: z.array(MedicalCodeTypesSchema).optional(),
      }),
      responses: {
        200: z.array(searchCodesResponseSchema),
      },
    },
  },
  { pathPrefix: '/:tenantId/medical-codes' },
);
