import { graphql } from '@eluve/graphql.tada';

export const chartAmendmentFragment = graphql(`
  fragment ChartAmendment on ChartAmendments @_unmask {
    __typename
    id
    keyPath
    value
    tenantId
    amendmentId
    createdAt
    updatedAt
  }
`);

export const pastBillingCodesFragment = graphql(`
  fragment PastBillingCodes on AppointmentBillingCodesSnapshots @_unmask {
    __typename
    id
    tenantId
    appointmentId
    description
    medical_code {
      __typename
      id
      code
      codeType
    }
    tenant_medical_code {
      __typename
      id
      code
      codeType
    }
  }
`);

export const billingCodeAmendmentFragment = graphql(
  `
    fragment BillingCodeAmendment on BillingCodeAmendments @_unmask {
      __typename
      id
      tenantId
      amendmentId
      createdAt
      billing_codes {
        ...PastBillingCodes
      }
    }
  `,
  [pastBillingCodesFragment],
);

export const amendmentBaseDetailsFragment = graphql(`
  fragment AmendmentBaseDetails on Amendments @_unmask {
    __typename
    id
    tenantId
    appointmentId
    createdAt
    updatedAt
    submittedAt
    userId
    user {
      __typename
      id
      firstName
      lastName
      email
    }
  }
`);

export const amendmentFragment = graphql(
  `
    fragment Amendment on Amendments @_unmask {
      ...AmendmentBaseDetails
      chart_amendments {
        ...ChartAmendment
      }
      billing_code_amendment {
        ...BillingCodeAmendment
      }
    }
  `,
  [
    amendmentBaseDetailsFragment,
    chartAmendmentFragment,
    billingCodeAmendmentFragment,
  ],
);

export const appointmentBillingCodeAmendments = graphql(
  `
    fragment AppointmentAmendments on Appointments @_unmask {
      __typename
      id
      amendments {
        ...AmendmentBaseDetails

        billing_code_amendment {
          ...BillingCodeAmendment
        }
      }
    }
  `,
  [amendmentBaseDetailsFragment, billingCodeAmendmentFragment],
);

export const originalAppointmentBillingCodes = graphql(
  `
    fragment OriginalAppointmentBillingCodes on Appointments @_unmask {
      __typename
      id
      past_billing_codes(where: { billingCodeAmendmentId: { _isNull: true } }) {
        ...PastBillingCodes
      }
    }
  `,
  [amendmentFragment, pastBillingCodesFragment],
);

export const appointmentAmendments = graphql(
  `
    fragment AppointmentAmendments on Appointments @_unmask {
      __typename
      id
      amendments {
        ...Amendment
      }
      past_billing_codes(where: { billingCodeAmendmentId: { _isNull: true } }) {
        ...PastBillingCodes
      }
    }
  `,
  [amendmentFragment, pastBillingCodesFragment],
);
