import {
  AlertCircle,
  AlertTriangle,
  ArrowRightToLine,
  ArrowUp,
  AudioWaveform,
  BookCopy,
  Calendar,
  Check,
  CheckCircle2,
  CheckSquare,
  ChevronDown,
  ChevronRight,
  ChevronUp,
  ChevronsUpDown,
  Circle,
  CircleHelp,
  ClipboardCopy,
  ClipboardEdit,
  ClipboardPaste,
  Clock,
  CloudDownload,
  Copy,
  CopyPlus,
  Ellipsis,
  Equal,
  ExternalLink,
  FastForward,
  FileEdit,
  FileSymlink,
  FileText,
  FileUp,
  Files,
  FlagTriangleRight,
  Gem,
  History,
  Home,
  Info,
  LayoutTemplate,
  Lightbulb,
  ListFilter,
  Loader2Icon,
  LoaderCircle,
  Mail,
  MapPin,
  Menu,
  MessageSquare,
  Mic,
  Minus,
  MinusCircle,
  PauseCircle,
  Pencil,
  PlayCircle,
  Plus,
  PlusCircle,
  RefreshCcw,
  RefreshCw,
  Rewind,
  RotateCcw,
  RotateCw,
  Search,
  SeparatorHorizontal,
  SeparatorVertical,
  Settings,
  ShieldCheck,
  Square,
  Star,
  Tag,
  Trash,
  Undo2,
  User,
  UserRoundPlus,
  WandSparkles,
  X,
} from 'lucide-react';
import React from 'react';
import { VariantProps } from 'tailwind-variants';

import { tv } from './cn';

const styles = tv({
  base: 'shrink-0',
  variants: {
    size: {
      '2xs': 'size-3.5 stroke-[2.1px]',
      xs: 'size-4 stroke-[1.9px]',
      sm: 'size-5 stroke-[1.8px]',
      md: 'size-6 stroke-[2.0px]',
      lg: 'size-7 stroke-[1.9px]',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export const Icons = {
  AlertCircle,
  AlertTriangle,
  ArrowRightToLine,
  AudioWaveform,
  ArrowUp,
  Calendar,
  Check,
  CheckCircle2,
  CheckSquare,
  ChevronDown,
  ChevronRight,
  ChevronUp,
  ChevronsUpDown,
  Circle,
  CircleHelp,
  BookCopy,
  ClipboardCopy,
  ClipboardEdit,
  ClipboardPaste,
  Clock,
  CloudDownload,
  Copy,
  CopyPlus,
  Ellipsis,
  Equal,
  ExternalLink,
  FastForward,
  FileEdit,
  Files,
  FileSymlink,
  FileUp,
  FileText,
  FlagTriangleRight,
  Gem,
  History,
  Home,
  Info,
  LayoutTemplate,
  Lightbulb,
  ListFilter,
  Loader2Icon,
  LoaderCircle,
  Mail,
  MapPin,
  Menu,
  MessageSquare,
  Mic,
  Minus,
  MinusCircle,
  PauseCircle,
  Pencil,
  PlayCircle,
  Plus,
  PlusCircle,
  RefreshCcw,
  RefreshCw,
  Rewind,
  RotateCcw,
  RotateCw,
  Search,
  SeparatorHorizontal,
  SeparatorVertical,
  Settings,
  ShieldCheck,
  Star,
  Square,
  Tag,
  Trash,
  Undo2,
  User,
  UserRoundPlus,
  WandSparkles,
  X,
};

type IconNames = keyof typeof Icons;

export type IconProps = VariantProps<typeof styles> & {
  className?: string;
  name: IconNames;
};

export const Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ name, size, className }, ref) => {
    const Component = Icons[name];
    const classes = styles({ size, className });

    return <Component ref={ref} className={classes} />;
  },
);
