import { useApiClient } from '@eluve/api-client-provider';
import { usePreventAutoReloadOnNewVersion } from '@eluve/blocks';
import { UserFileTypesEnum } from '@eluve/graphql-types';
import { useNamedLogger } from '@eluve/logger';

export const useUploadUserFile = (tenantId: string) => {
  const logger = useNamedLogger('useUploadUserFile');
  const apiClient = useApiClient();

  const onUploadSuccess = async (userUploadId: string): Promise<void> => {
    await apiClient.users.markUserFileUploadComplete({
      params: { tenantId },
      body: { userUploadId },
    });
  };

  const onUploadFailure = async (
    userUploadId: string,
    errorMessage: string,
  ): Promise<void> => {
    logger.error('Failed to upload file', { errorMessage });

    await apiClient.users.markUserFileUploadFailed({
      params: { tenantId },
      body: { userUploadId },
    });
  };

  const uploadUserFileToGCS = async ({
    file,
    mimeType,
    extension,
    fileType,
  }: {
    file: File;
    mimeType: string;
    extension: string;
    fileType: UserFileTypesEnum;
  }): Promise<boolean> => {
    usePreventAutoReloadOnNewVersion.setState(true);

    const prepareUserFileUploadResponse =
      await apiClient.users.prepareUserFileUpload({
        params: { tenantId },
        body: { extension, fileType },
      });

    if (prepareUserFileUploadResponse.status !== 200) {
      logger.debug('Failed to prepare user file upload');
      return false;
    }
    const { uploadUrl, userUploadId } = prepareUserFileUploadResponse.body;

    const headers = new Headers();
    headers.append('Content-Type', mimeType);

    const uploadResponse = await fetch(uploadUrl, {
      method: 'PUT',
      headers,
      body: file,
    });

    if (!uploadResponse.ok) {
      onUploadFailure(userUploadId, 'Could not upload using signed url');
      return false;
    }

    await onUploadSuccess(userUploadId);

    usePreventAutoReloadOnNewVersion.setState(false);

    return true;
  };

  return {
    uploadUserFileToGCS,
  };
};
