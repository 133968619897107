import { useQuery } from '@apollo/client';

import { useCompleteFragment } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';
import { formatHumanName } from '@eluve/utils';

const userInfoFragment = graphql(`
  fragment UserInfoFragment on Users {
    __typename
    id
    firstName
    lastName
    email
  }
`);

const getUserNameQuery = graphql(
  `
    query getUserName($userId: uuid!) {
      usersByPk(id: $userId) {
        ...UserInfoFragment
      }
    }
  `,
  [userInfoFragment],
);

export const useUserName = (userId: string, placeholder?: string) => {
  const userInfo = useCompleteFragment({
    fragment: userInfoFragment,
    key: {
      id: userId,
    },
    strict: false,
  });

  const { loading } = useQuery(getUserNameQuery, {
    variables: {
      userId,
    },
    skip: userInfo !== null || userId === '',
  });

  if (loading) {
    return '...';
  }

  if (!userInfo) {
    return placeholder ?? 'unknown user';
  }

  if (userInfo.firstName || userInfo.lastName) {
    return formatHumanName(userInfo.firstName, userInfo.lastName);
  }

  return userInfo.email;
};
