import { Text, View } from '@react-pdf/renderer';

import { Block } from '@eluve/llm-outputs';

import { tw } from '../pdf-tw';
import { commonStyles } from '../styles';
import { PdfAmendment } from '../types';

import { PdfBlock } from './PdfBlock';

export const PdfGroupField: React.FC<{
  block: Block;
  amendments: PdfAmendment[];
}> = ({ block, amendments }) => {
  if (block.type !== 'group') return null;
  return (
    <View key={block.key}>
      {block.label && (
        <Text style={tw(commonStyles.headingText())}>{block.label}</Text>
      )}
      <View style={tw(commonStyles.content())}>
        {block.blocks.map((nestedBlock, i) => (
          <PdfBlock key={i} amendments={amendments} block={nestedBlock} />
        ))}
      </View>
    </View>
  );
};
