import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { FilterableAppointmentsTable } from '@eluve/frontend-feature-appointment';
import { AppointmentsBoolExp } from '@eluve/graphql-types';

export const PatientAppointmentsList: React.FC = () => {
  const { patientId } = useParams() as {
    patientId: string;
  };

  const appointmentFilterConditions: AppointmentsBoolExp[] = useMemo(() => {
    const filterConditions: AppointmentsBoolExp[] = [
      {
        patientId: { _eq: patientId },
      },
    ];
    return filterConditions;
  }, [patientId]);

  return (
    <FilterableAppointmentsTable
      baseFilterConditions={appointmentFilterConditions}
    />
  );
};
